import React, { useContext, useEffect, useState } from "react";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import AppContext from "../../context/store";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { MdOutlineDragHandle } from "react-icons/md";

const StaffOrderDialog = (props) => {
  const context = useContext(AppContext);
  const [orderedStaffs, setOrderedStaffs] = useState([]);
  const matches = useMediaQuery("(max-width: 576px)");

  useEffect(() => {
    setOrderedStaffs([...props.staffs]);
  }, [props.staffs]);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newStaffs = Array.from(orderedStaffs);
    const [removed] = newStaffs.splice(result.source.index, 1);
    newStaffs.splice(result.destination.index, 0, removed);

    const orderedList = newStaffs.map((staff, index) => ({
      staff_id: staff.id,
      order_no: index + 1,
    }));

    setOrderedStaffs(newStaffs);
    props.orderedHandler(orderedList);
  };

  return (
    <AlertDialog
      title={context.t(`component.staffOrderDialog.staffOrderTitle`)}
      open={props.open}
      maxWidth="lg"
      fullWidth={true}
      buttons={[
        {
          title: context.t(`component.staffOrderDialog.closeButtonTitle`),
          icon: "close",
          backgroundColor: "primary",
          textColor: "white",
        },
      ]}
      closeHandler={props.closeHandler}
    >
      <DialogContent>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable
            droppableId="staffs"
            direction="vertical"
          >
            {(provided) => (
              <StaffList
                {...provided.droppableProps}
                ref={provided.innerRef}
                $isVertical={matches}
              >
                {orderedStaffs.map((staff, index) => (
                  <Draggable
                    key={staff.id}
                    draggableId={String(staff.id)}
                    index={index}
                  >
                    {(provided) => (
                      <StaffItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        $isVertical={matches}
                      >
                        <MdOutlineDragHandle style={{ fontSize: "24px", color: "#3189ec", opacity: 0.6, transition: "opacity 0.2s" }} />
                        <PositionBadge>{index + 1}</PositionBadge>
                        <Avatar

                          src={
                            staff.detail.profile_photo
                              ? `${context.api_endpoint}/${context.state.company_id}/${staff.detail.profile_photo}`.replace(
                                "api/v1/",
                                ""
                              )
                              : staff.sex === 0
                                ? require("../../assets/images/staff_woman.jpg")
                                : staff.sex === 1
                                  ? require("../../assets/images/staff_man.jpg")
                                  : require("../../assets/images/profile_photo.svg")
                          }
                          alt={staff.full_name}
                        />
                        <StaffInfo>
                          <StaffName>{staff.full_name}</StaffName>
                        </StaffInfo>
                      </StaffItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </StaffList>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
    </AlertDialog>
  );
};

export default React.memo(StaffOrderDialog);

// Styled Components
const DialogContent = styled.div`
  padding: 24px;
  background: white;
  min-height: 300px;
`;

const StaffList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;


const StaffItem = styled.li`
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 8px;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
`;

const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e0e0e0;
`;

const PositionBadge = styled.span`
  background: #3189ec;
  color: white;
  width: 24px;
  height: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  flex-shrink: 0;
`;

const StaffInfo = styled.div`
  flex-grow: 1;
`;

const StaffName = styled.span`
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #333;
`;