import React, { useDebugValue, useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../context/store";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const AppointmentNotification = (props) => {
  const context = useContext(AppContext);

  const history = useHistory();

  const closeHandler = () => {
    props.readNotifications();
    context.dispatch({
      type: "SET_APPOINTMENT_NOTIFICATION_DIALOG",
      payload: { open_appointment_notificaiton_dialog: false },
    });
  };

  const playSound = () => {
    const notificationSound = new Audio(
      require("../../assets/sounds/appointment_notification.mp3")
    );
    notificationSound.play().catch(err => console.log('Ses çalma hatası:', err));
  };

  useEffect(() => {
    if (context.state.appointment_notification === true && !context.state.open_appointment_notificaiton_dialog) {
      playSound();
      context.dispatch({
        type: "SET_APPOINTMENT_NOTIFICATION_DIALOG",
        payload: { open_appointment_notificaiton_dialog: true },
      });
    }
  }, [context.state.appointment_notification]);

  return (
    <AlertDialog
      open={context.state.appointment_notification}
      disableBackdropClick={true}
      maxWidth="sm"
      fullWidth={false}
      buttons={[
        {
          title: context.t(
            `component.appointment_notification.appointmentsButtonTitle`
          ),
          icon: "insert_invitation",
          backgroundColor: "primary",
          textColor: "white",
          onClick: () => {
            history.push("/appointments/online");
          },
        },
        {
          title: context.t(
            `component.appointment_notification.closeButtonTitle`
          ),
          icon: "check",
          backgroundColor: "primary",
          textColor: "white",
        },
      ]}
      closeHandler={closeHandler}
    >
      <DialogContainer>
        <img
          src={require("../../assets/images/appointment_notification.png")}
          alt=""
          style={{ width: "100px" }}
        />
        <p>{context.t(`component.appointment_notification.description`)}</p>
      </DialogContainer>
    </AlertDialog>
  );
};

export default AppointmentNotification;

const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 20px;
  }
`;
