


import React, { useContext, useEffect, useState, useCallback } from "react";
import AppContext from "../../context/store";
import DatePickerInput from "../../theme/CustomMUI/DatePickerInput";
import FullScreenDialog from "../../theme/CustomMUI/FullScreenDialog";
import Input from "../../theme/CustomMUI/Input";
import { Column, Grid } from "../../theme/Grid";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import moment from "moment";
import Select from "../../theme/CustomMUI/Select";
import Table from "../../theme/Table";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ExcelButton from "../Buttons/ExcelButton";
import styled from "styled-components";
import { Clear, DateRange, Search } from "@material-ui/icons";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import { debounce } from 'lodash';
import AppointmentCardDetail from "./AppointmentCardDetail";
import useHandleParams from "../../functions/HandleParams";
import { MenuItem, TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import Button from "../../theme/Button";
import youtube_image from "../../assets/images/youtube_image.jpg";
import { IoMdClose } from "react-icons/io";
import { Dialog } from "@material-ui/core";
import YouTube from "react-youtube";

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
`;

const styles = (theme) => ({
  root: {
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgba(249, 186, 132, 0.21) !important",
    },
  },
});

const RepeatAppointment = ({ classes }) => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const HandleParams = useHandleParams();
  const app_name = process.env.APP_NAME;

  const [loaded, setLoaded] = useState(false);
  const [isCreatedAtASCDESC, setIsCreatedAtASCDESC] = useState(false); // true -> asc | false -> desc
  const [isNameASCDESC, setIsNameASCDESC] = useState(false); // true -> asc | false -> desc
  const [isAppointmentAtASCDESC, setIsAppointmentAtASCDESC] = useState(false); // true -> asc | false -> desc
  const [isListed, setIsListed] = useState(null)
  const [fieldId, setFieldId] = useState(null)
  const [previewModal, setPreviewModal] = useState(false)
  const [previewData, setPreviewData] = useState([])
  const [deleteModal, setDeleteModal] = useState(false)
  const [selectedDelete, setSelectedDelete] = useState(null)
  const [youtubePopUp, setYoutubePopUp] = useState(false);
  const urlPage = parseInt(
    new URLSearchParams(window.location.search).get("page")
  );
  const [preview_pagination, setPreviewPagination] = useState({
    total_page: null,
    page: urlPage ? urlPage : 1,
    onChange: (page) => setPreviewPagination((prev) => ({ ...prev, page: page })),
  })
  const [pagination, setPagination] = useState({
    total_page: null,
    page: urlPage ? urlPage : 1,
    onChange: (page) => setPagination((prev) => ({ ...prev, page: page })),
  });
  const [appointmentData, setAppointmentData] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [selectedList, setSelectedList] = useState({
    order: 'desc',
    orderBy: 'created_at'
  })
  const [staff_id, setStaffID] = useState(null);
  const [service_key, setServiceKey] = useState("");
  const [customer_key, setCustomerKey] = useState("");
  const [appointment_state, setAppointmentState] = useState(null);

  const [filter, setFilter] = useState({
    between: {
      start: moment().local().subtract(30, "days").toISOString(),
      end: moment().local().add(1, "days").toISOString(),
    },
  });
  const searchParams = new URLSearchParams(location.search);

  const [tempFilter, setTempFilter] = useState({
    between: {
      start: moment().local().subtract(30, "days").toISOString(),
      end: moment().local().add(1, "days").toISOString(),
    },
  });
  const [repeat_types, setRepeatTypes] = useState([
    {
      id: 'DAILY',
      name: 'Günlük'
    },
    {
      id: 'WEEKLY',
      name: 'Haftalık'
    },
    {
      id: 'MONTHLY',
      name: 'Aylık'
    }
  ])

  const [filterPicker] = useState([
    {
      id: 1,
      label: context.t(`accounting.today`),
      offset: 1,
      betweenDateEnd: moment().local().toISOString(),
      betweenDateStart: moment().local().add(-1, "days").toISOString(),
    },
    {
      id: 2,
      label: context.t(`accounting.thisWeek`),
      offset: 3,
      betweenDateEnd: moment().local().toISOString(),
      betweenDateStart: moment().local().add(-7, "days").toISOString(),
    },
    {
      id: 3,
      label: context.t(`accounting.thisMonth`),
      offset: 4,
      betweenDateEnd: moment().local().toISOString(),
      betweenDateStart: moment().local().add(-30, "days").toISOString(),
    },
  ]);
  const [selected_filter_picker, setSelected_filter_picker] = useState({
    id: 1,
    label: context.t(`accounting.today`),
    offset: 1,
  },);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [readyToUseFilter, setReadyToUseFilter] = useState(null);
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: moment().local().subtract(30, "days").format("DD/MM/YYYY"),
      end: moment().local().add(1, "days").format("DD/MM/YYYY"),
    },
  });
  const [customerStatus, setCustomerStatus] = useState([
    {
      label: context.t(`receipts.customerStatus.attend`),
      color: "#1D8B3E",
      value: 0,
    },
    {
      label: context.t(`receipts.customerStatus.notAttend`),
      color: "#FF2C6F",
      value: 1,
    },
    {
      label: context.t(`receipts.customerStatus.waiting`),
      color: "#FF9016",
      value: 2,
    },
    {
      label: context.t(`appointments.detail.appointmentStatus.accepted`),
      color: "#54AEAE",
      value: 3,
    },
    {
      label: context.t(`appointments.detail.appointmentStatus.inProcess`),
      color: "#7A45AE",
      value: 4,
    },
  ])

  const getAppointmentsByFilter = () => {
    setLoaded(false);
    const urlPage = parseInt(new URLSearchParams(location.search).get("page"));
    const { between } = filter;
    Axios.get(
      `${context.api_endpoint}/company/appointments/recurrents`, {
      params: {
        ...(selected_filter_picker === null ? {
          start: between.start.split("T")[0],
          end: between.end.split("T")[0],
        } : {
          start: filterPicker.find((el) => el.id === selected_filter_picker.id).betweenDateStart.split("T")[0],
          end: filterPicker.find((el) => el.id === selected_filter_picker.id).betweenDateEnd.split("T")[0],
        }),
        page: pagination.page ? pagination.page : urlPage ? urlPage : 1,
        limit: 10,
        isbetween: selected_filter_picker ? null : true,
        staffid: staff_id,
        servicekey: service_key ? service_key : null,
        customerkey: customer_key ? customer_key : null,
        order_type: selectedList.order !== '' ? selectedList.order : null,
        order_by: selectedList.orderBy !== '' ? selectedList.orderBy : null,
        appointmentstate: appointment_state,
        field: fieldId,
      }
    }
    )
      .then(({ data }) => {
        setLoaded(true);
        setAppointmentData(
          data.records.map((appointmentData) => {
            return ({
              frequency: repeat_types?.find((el) => el.id === appointmentData.frequency)?.name,
              id: appointmentData.id,
              receipt_id: appointmentData.receipt_id,
              customer_full_name: appointmentData.customer_name + ' ' + appointmentData.customer_surname,
              created_at: moment(appointmentData.created_at).format('DD.MM.YYYY HH:mm'),
              customer_id: appointmentData.customer_id,
              service_name: appointmentData.service_name
            })
          }),
        );
        setPagination({
          ...pagination,
          // page: data.page,
          total_page: data.records.length,
        });
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error(e.response?.data?.message);
        } else {
          toast.warning(e?.response?.data?.message);
        }
      });
  };

  const deleteRepeatAppointment = (id) => {
    Axios.delete(`${context.api_endpoint}/company/appointments/recurrents/${id}`).then((response) => {
      if (response.status === 200 || response.status === 201) {
        toast.success('Başarılı bir şekilde silindi')
        setDeleteModal(!deleteModal)
        getAppointmentsByFilter()
      }
    }).catch((e) => {
      if (e.response?.status === 401) {
        toast.error(e.response?.data?.message);
      } else {
        toast.warning(e?.response?.data?.message);
      }
    });
  }



  const getSingleRepeatAppointment = (id) => {
    Axios.get(`${context.api_endpoint}/company/appointments/recurrents/${id}`, {
      params: {
        limit: 100
      }
    }).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setPreviewData(response.data.records.map((previewMap, index) => {
          return ({
            id: previewMap.id,
            staff_id: previewMap.staff_name + " " + previewMap.staff_surname,
            service_id: previewMap.service_name,
            customer_state: customerStatus?.find((el) => el.value === previewMap?.customer_state)?.label,
            customer_color: customerStatus?.find((el) => el.value === previewMap?.customer_state)?.color,
            appointment_start_date: previewMap.appointment_start_date,
            appointment_end_date: previewMap.appointment_end_date,
            start_finish_time: `${moment(previewMap.appointment_start_date).format('HH:mm')}-${moment(previewMap.appointment_end_date).format('HH:mm')}`,
            is_available: previewMap.is_available ? process.env.APP_NAME === 'management' ? 'Active' : 'Aktif' : process.env.APP_NAME === 'management' ? 'Passive' : 'Pasif'
          })
        }))
        setPreviewPagination({
          ...preview_pagination,
          page: response.data.page,
          total_page: response.data.records.length,
        });
        setPreviewModal(!previewModal)
      }
    }).catch((e) => {
      if (e.response?.status === 401) {
        toast.error(e.response?.data?.message);
      } else {
        toast.warning(e?.response?.data?.message);
      }
    });
  }



  const getAppointmentIndex = () => {
    Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        if (response.status === 200) {
          setStaffs([...response.data.data]);
        }
      }
    );
  };

  useEffect(() => {
    getAppointmentIndex();
  }, [])

  const handleFilterPickerClick = (selectedFilter) => {
    setPagination((prev) => ({ ...prev, page: 1 }));
    HandleParams('page', 1);
    setSelected_filter_picker(selectedFilter);

    let newStart, newEnd;

    switch (selectedFilter.offset) {
      case 1: // Bugün
        newStart = moment().startOf('day');
        newEnd = moment().endOf('day');
        break;
      case 3: // Bu Hafta
        newStart = moment().startOf('week');
        newEnd = moment().endOf('week');
        break;
      case 4: // Bu Ay
        newStart = moment().startOf('month');
        newEnd = moment().endOf('month');
        break;
      default:
        return;
    }

    setFilter({
      between: {
        start: newStart.toISOString(),
        end: newEnd.toISOString(),
      }
    });

    setReportsDate({
      between: {
        start: newStart.format("DD/MM/YYYY"),
        end: newEnd.format("DD/MM/YYYY"),
      }
    });


    setTempFilter({
      between: {
        start: newStart.toISOString(),
        end: newEnd.toISOString(),
      }
    });
  };

  useEffect(() => {
    getAppointmentsByFilter();
  }, [
    selectedList,
    selected_filter_picker,
    filter.between.start,
    filter.between.end,
    customer_key,
    pagination.page,
    service_key,
    staff_id,
    appointment_state,
    isCreatedAtASCDESC,
    isNameASCDESC,
    isAppointmentAtASCDESC
  ]);

  const debouncedSetServiceKey = useCallback(
    debounce((value) => {
      setServiceKey(value);
    }, 800),
    []
  );

  const debouncedSetCustomerKey = useCallback(
    debounce((value) => {
      setCustomerKey(value);
    }, 800),
    []
  );


  useEffect(() => {
    return () => {
      debouncedSetServiceKey.cancel();
      debouncedSetCustomerKey.cancel();
    };
  }, [debouncedSetServiceKey, debouncedSetCustomerKey]);

  const getAppointmentsForSelectBox = async (staff_id, date) => {
    try {
      const response = await Axios.get(
        `${context.api_endpoint}/company/appointment/staff/${staff_id}/date/${moment(date).format("YYYY-MM-DD")}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return [];
    }
  };

  useEffect(() => {
    setLoaded(true);
    if (location.state?.selectedFilterPicker) {
      setSelected_filter_picker(location.state.selectedFilterPicker);
      handleFilterPickerClick(location.state.selectedFilterPicker);
    }
    setLoaded(false);
  }, [location.state]);

  const opts = {
    height: "330",
    width: "420",
  };


  const createAppointment = (repeatId) => {
    Axios.post(`${context.api_endpoint}/company/receipt/insert/by/appointment`, {
      recurrence_id: repeatId
    }).then((response) => {
      if (response.status === 200 || response.status === 201) {
        history.push(`/receipts/detail/${response?.data?.data?.id}`)
        getAppointmentsByFilter()
      }
    }).catch((e) => {
      if (e.response?.status === 401) {
        toast.error(e.response?.data?.message);
      } else {
        toast.warning(e?.response?.data?.message);
      }
    });
  }

  return (
    <Grid>
      <div style={{
        position: "absolute",
        zIndex: "11",
        top: "91px",
        right: "25px",
        display: "flex",
        alignItems: "center"
      }}>
        {app_name === "salon" && (
          <img
            onClick={() => {
              setYoutubePopUp(true);
            }}
            src={youtube_image}
            style={{
              width: "70px",
              height: "37px",
              objectFit: "contain",
              cursor: "pointer",
            }}
          />
        )}
      </div>
      {app_name === "salon" && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={youtubePopUp}
          onClose={() => setYoutubePopUp(false)}
        >
          <div style={{ backgroundColor: "black" }}>
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <IoMdClose
                style={{
                  cursor: "pointer",
                  marginRight: "2px",
                  marginTop: "2px",
                }}
                onClick={() => setYoutubePopUp(false)}
                size={"24px"}
                color="white"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"kuEh4uQQMlI"} opts={opts} />
                </div>
              </div>
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle2`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"afBhNMTomfc"} opts={opts} />
                </div>
              </div>
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle3`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"vngBLPm-q0E"} opts={opts} />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <Column
        className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
        style={{ borderBottom: "1px solid #b2b2b2", display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Column
          className="xs-12 sm-12 md-8 lg-6"
          style={{ height: "63px", display: "flex", alignItems: "center" }}
        >
          <ShortButtonContainer>
            {filterPicker.map(filter => (
              <ShortButton
                key={filter.id}
                onClick={() => handleFilterPickerClick(filter)}
                style={{
                  backgroundColor: selected_filter_picker?.id === filter.id
                    ? filter.id === 1 ? "rgb(49, 169, 243, 0.14)"
                      : filter.id === 2 ? "rgb(29, 139, 62, 0.21)"
                        : "rgb(254, 151, 151, 0.19)"
                    : "",
                  border: filter.id === 1 ? "1px solid rgb(91, 187, 245, 0.41)"
                    : filter.id === 2 ? "1px solid rgb(58, 154, 87, 0.35)"
                      : "1px solid rgb(185, 178, 178, 0.65)",
                  color: filter.id === 1 ? "rgb(49, 169, 243)"
                    : filter.id === 2 ? "rgb(29, 139, 62)"
                      : "rgb(254, 111, 111)"
                }}
              >
                {filter.label}
              </ShortButton>
            ))}
          </ShortButtonContainer>
        </Column>

        <Column
          className="xs-12 sm-12 md-4 lg-4"
          style={{ height: "63px", display: "flex", alignItems: "center" }}
        >
          <FilterContainer>
            <FilterButton
              style={{ height: '42px', marginBottom: '10px' }}
              onClick={() => {
                setFilterModalOpen(true);
                setSelected_filter_picker(null);
                setPagination((prev) => ({ ...prev, page: 1 }));
                HandleParams('page', 1);
                setReadyToUseFilter(1);
              }}
            >
              <DateRange />
              <span style={{ fontWeight: "500", fontSize: "1.2em", paddingLeft: "1vw" }}>
                {reportsDate.between.start} - {reportsDate.between.end}
              </span>
            </FilterButton>
            {/* <Column
              className="xs-12 sm-12 md-12 lg-6"
              style={{ borderLeft: "1px solid #b2b2b2" }}
            >
              <FilterContainer>
                <TextField
                  variant="outlined"
                  className={classes.root}
                  select
                  label={context.t(`appointments.create.selectedStaffInputLabel`)}
                  SelectProps={{ IconComponent: () => null }}
                  value={selectedStaff && selectedStaff.id ? selectedStaff.id : ""}
                  InputProps={{
                    endAdornment: (
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // setDatas((prev) => ({ ...prev, pagination: { ...prev.pagination, page: 1 } }))
                          // updatePage(1)
                          setSelectedStaff(null);
                        }}
                      >
                        <Clear />
                      </div>
                    ),
                  }}
                  style={{
                    backgroundColor: "rgba(249, 186, 132, 0.21)",
                    color: "rgb(219, 146, 92)",
                    cursor: "pointer",
                    borderRadius: "8px",
                    marginLeft: "8px",
                    padding: '15px !important',
                    border: "1px solid rgba(249, 186, 132, 0.51)",
                    width: "80%",
                  }}
                  onChange={(event) => {
                    const selectedValue = event.target.value;
                    // setDatas((prev) => ({ ...prev, pagination: { ...prev.pagination, page: 1 } }));
                    // updatePage(1);
                    setSelectedStaff({ id: selectedValue });
                  }}
                >
                  {staffs &&
                    staffs.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={`${option && option.id}`}
                        id={`${option && option.id}`}
                      >
                        {staffs[index].full_name}
                      </MenuItem>
                    ))}
                </TextField>
              </FilterContainer>
            </Column> */}
          </FilterContainer>
        </Column>
      </Column>

      <Grid>
        <Column className="xs-12 sm-12">
          <Table
            refreshAction={() => {
              getAppointmentsByFilter()
            }}
            loaded={loaded}
            noDataText={'Bulunamadı'}
            headings={{
              created_at: {
                label: context.t(`['appointments/filter'].headings.createdAt`),
                sortable: {
                  0: [{ field: "`stock_items`.`created_at`", order: "asc" }],
                  1: [{ field: "`stock_items`.`created_at`", order: "desc" }],
                },
                isClicked: selectedList.orderBy === 'created_at' && selectedList.order === 'asc', // Hem orderBy hem de order kontrolü
                onClick: () => {
                  setPagination((prev) => ({ ...prev, page: 1 }))
                  HandleParams('page', 1)
                  setSelectedList((prev) => ({
                    orderBy: 'created_at',
                    order: prev.orderBy === 'created_at'
                      ? (prev.order === 'asc' ? 'desc' : 'asc')
                      : 'asc',
                  }));
                },
              },
              "customer_full_name": {
                label: context.t(
                  `['appointments/filter'].headings.customerFullName`
                ),
                sortable: {
                  0: [{ field: "`stock_items`.`customer_name`", order: "asc" }],
                  1: [{ field: "`stock_items`.`customer_name`", order: "desc" }],
                },
                isClicked: selectedList.orderBy === 'customer_name' && selectedList.order === 'asc', // Hem orderBy hem de order kontrolü
                onClick: () => {
                  setPagination((prev) => ({ ...prev, page: 1 }))
                  HandleParams('page', 1)
                  setSelectedList((prev) => ({
                    orderBy: 'customer_name',
                    order: prev.orderBy === 'customer_name'
                      ? (prev.order === 'asc' ? 'desc' : 'asc')
                      : 'asc',
                  }));
                },
              },
              "service_name": {
                label: context.t(
                  `['appointments/filter'].headings.serviceName`
                ),
              },
              frequency: {
                label: context.t(`appointments.detail.repeatFrequency`),
                sortable: {
                  0: [{ field: "`stock_items`.`frequency`", order: "asc" }],
                  1: [{ field: "`stock_items`.`frequency`", order: "desc" }],
                },
                isClicked: selectedList.orderBy === 'frequency' && selectedList.order === 'asc', // Hem orderBy hem de order kontrolü
                onClick: () => {
                  setPagination((prev) => ({ ...prev, page: 1 }))
                  HandleParams('page', 1)
                  setSelectedList((prev) => ({
                    orderBy: 'frequency',
                    order: prev.orderBy === 'frequency'
                      ? (prev.order === 'asc' ? 'desc' : 'asc')
                      : 'asc',
                  }));
                },
              },
              // last_appointment_time: {
              //   label: 'Son Randevu Tarihi'
              // },
              _: { label: context.t(`component.actionHeadingText`) },
            }
            }
            rows={appointmentData}
            pagination={pagination}
            buttons={[
              {
                title: context.t(`appointments.repeatButtons.showAppointment`),
                icon: "launch",
                textColor: "orange",
                backgroundColor: 'orange-opactiy',
                transitionEffect: true,
                pushEffect: true,
                onClick: (row) => {
                  getSingleRepeatAppointment(row?.id)
                },
              },
              {
                title: context.t(`appointments.repeatButtons.goAppointment`),
                icon: "launch",
                textColor: "primary",
                transitionEffect: true,
                pushEffect: true,
                onClick: (row) => {
                  if (row.receipt_id) {
                    history.push(`/receipts/detail/${row.receipt_id}`)
                  } else {
                    createAppointment(row.id)
                  }
                },
              },
              // {
              //   title: 'Düzenle',
              //   icon: "update",
              //   textColor: "green",
              //   backgroundColor: 'green-opacity',
              //   transitionEffect: true,
              //   pushEffect: true,
              //   onClick: (row) => {

              //   },
              // },
              {
                title: context.t(`appointments.repeatButtons.cancelAppointment`),
                textColor: "red",
                icon: "delete_forever",
                transitionEffect: true,
                onClick: (row) => {
                  setSelectedDelete(row.id)
                  setDeleteModal(!deleteModal)
                }
              },

            ]}
          // onRowClick={(row) => {
          //   try {
          //     const startDate = moment(row.appointment_start_date, "DD MMMM YYYY HH:mm");
          //     const endDate = moment(row.appointment_end_date);

          //     setHourDetail({
          //       start_hour: startDate.format("HH:mm"),
          //       end_hour: endDate.format("HH:mm")
          //     });
          //     const appointmentData = {
          //       ...row,
          //       start_at: startDate.format("YYYY-MM-DD HH:mm:ss"),
          //       end_at: endDate.format("YYYY-MM-DD HH:mm:ss"),
          //       packet: row.packet || null,
          //       service: row.service || null
          //     };

          //     setAppointmentDate(startDate.toDate());
          //     setSelectedStaff(row.staff);
          //     set_Appointment(appointmentData);
          //     setAppointmentDetailModal(true);

          //   } catch (error) {
          //     console.error("Error in onRowClick:", error);
          //     toast.error("Randevu detayı açılırken bir hata oluştu");
          //   }
          // }}
          />

        </Column>
      </Grid>
      <FullScreenDialog
        className="appointment-add-dialog"
        maxWidth="md"
        //open
        open={previewModal}
        title={'Önizle'}
        onClose={() => setPreviewModal(!previewModal)}
      >

        <Grid className='d-flex flex-row p-3' style={{ maxHeight: '600px', overflowY: 'scroll', width: '93%' }}>
          <Table
            // refreshAction={() => {
            //   setIsAppointmentAtASCDESC(false);
            //   setIsCreatedAtASCDESC(false);
            //   setIsNameASCDESC(false);
            // }}
            isExist={false}
            loaded={true}
            headings={{
              // start_date: {
              //   label: "Randevu Tarihi",
              //   customStyle: (row) => ({ marginLeft: '5px', backgroundColor: row.is_available ? '' : '#ffc2c2' })
              // },
              appointment_start_date: {
                label: context.t(`appointments.repeatButtons.appointmentDate`),
                customStyle: (row) => ({ marginLeft: '5px', backgroundColor: row.is_available ? '' : '#ffc2c2' }),
                render: (row, renderIndex) => {
                  return (
                    <>
                      {/* <DatePickerInput
                        format="DD MMMM YYYY - dddd"
                        textColor="primary"
                        showTodayButton={true}
                        minDate={new Date('2019-01-01')}
                        value={
                          moment(row.start_date)?.utcOffset('+03:00')?.format('DD MMMM YYYY - dddd')
                        }
                        onChange={(date) => {
                          console.log(moment(date))
                          // localStorage.setItem("selected_date", moment(date));
                          // this.setState({
                          //   adj_selected_date: date,
                          //   selected_date: date,
                          //   dialog: false,

                          //   available_appointment_expanded: false,
                          //   selected_time: null,
                          // });
                        }}
                      /> */}
                      <DatePickerInput
                        textColor="primary"
                        disabled
                        format="DD/MM/YYYY"
                        showTodayButton={true}
                        inputStyle={{ width: '80px' }}
                        value={moment.utc(row.appointment_start_date).format("MM/DD/YYYY")}
                      // onChange={(date) => {
                      //   const dateInput = moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
                      //   const timeInput = moment.utc(row.start_date).format("HH:mm:ss.SSS");

                      //   const isoDate = moment(`${dateInput} ${timeInput}`, "YYYY-MM-DD HH:mm:ss.SSS").toISOString();

                      //   this.setState((prevState) => ({
                      //     previewData: prevState.previewData.map((data) => {
                      //       if (data.id === row.id) {
                      //         return {
                      //           ...data,
                      //           start_date: isoDate,
                      //         };
                      //       }
                      //       return data;
                      //     }),
                      //     previewResponseData: prevState.previewResponseData.map((data, index) => {
                      //       if (index === renderIndex) {
                      //         return {
                      //           ...data,
                      //           start_date: isoDate,
                      //         };
                      //       }
                      //       return data;
                      //     }),
                      //   }));
                      // }}
                      />
                    </>
                  )
                }
              },
              // "customer_full_name": {
              //   label: context.t(
              //     `['appointments/filter'].headings.customerFullName`
              //   ),
              //   customStyle: (row) => ({ backgroundColor: row.is_available ? '' : '#ffc2c2' })
              // },
              // "is_available": {
              //   label: 'Aktiflik Durumu',
              //   customStyle: (row) => ({ backgroundColor: row.is_available ? '#d9fce5' : '#ffc2c2' })
              // },
              "customer_state": {
                label: context.t(`appointments.repeatButtons.appointmentStatus`),
                customStyle: (row) => ({ color: row.customer_color })
              },
              "service_id": {
                label: context.t(
                  `['appointments/filter'].headings.serviceName`
                ),
              },
              "staff_id": {
                label: context.t(
                  `['appointments/filter'].headings.staffFullName`
                ),
              },
              start_finish_time: {
                label: context.t(`appointments.repeatButtons.startEnd`),
              }
              // repeat_time: {
              //   label: 'Tekrar Sıklığı'
              // },
              // end_date: {
              //   label: 'Son Randevu Tarihi'
              // },
            }}
            rows={previewData}
            //pagination={preview_pagination}
            onRowClick={(row) => {
              history.push(`/appointments`, { appointment_id: row?.id, data: row })
            }}
          />
        </Grid>
      </FullScreenDialog>

      <AlertDialog
        title={<h3 style={{ textAlign: "center" }}>Tekrarlayan Randevuyu Sil</h3>}
        open={deleteModal}
        maxWidth="xl"
        closeHandler={() => {
          setDeleteModal(false)
          setSelectedDelete(null)
        }}
        buttons={[
          {
            title: 'Kalan Randevuları Sil',
            backgroundColor: "orange-opacity",
            textColor: "orange",
            onClick: () => {
              deleteRepeatAppointment(selectedDelete)
            },
          },
          {
            title: context.t(`accounting.filterArea.cancel`),
            icon: "close",
            textColor: "grey",
            backgroundColor: 'grey-opacity',
            onClick: () => {
              setSelectedDelete(null)
              setDeleteModal(!deleteModal)
            },
          },
        ]}
      >
        <div style={{ textAlign: 'center', marginBottom: '10px', width: '100%' }}>
          <span>
            Tekrarlayan randevuyu silmek üzeresiniz <br />
            tüm randevuları mı silmek istersiniz <br />
            yoksa sadece kalan randevuları mı
          </span>
        </div>
      </AlertDialog>
      <AlertDialog
        title={<h3 style={{ textAlign: "center" }}>{context.t(`accounting.filterArea.header`)}</h3>}
        open={filterModalOpen}
        maxWidth="sm"
        closeHandler={() => setFilterModalOpen(false)}
        buttons={[
          {
            title: context.t(`accounting.filterArea.approve`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              setFilterModalOpen(false);
              setFilter(tempFilter);
              setSelected_filter_picker(null);
            },
          },
          {
            title: context.t(`accounting.filterArea.cancel`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setTempFilter(filter);
              setFilterModalOpen(false);
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "100%"
              }}
            >
              <DatePickerInput
                label={context.t(`reports.startInputLabel`)}
                value={moment(tempFilter.between.start).toDate()}
                maxDate={moment(tempFilter.between.end).toDate()}
                onChange={(date) => {
                  setTempFilter(prev => ({
                    between: {
                      ...prev.between,
                      start: moment(date).toISOString(),
                    }
                  }));
                  setReportsDate(prev => ({
                    between: {
                      ...prev.between,
                      start: moment(date).format("DD/MM/YYYY"),
                    }
                  }));
                }}
                fullWidth
                minDate={new Date('2019-01-01')}
              />
              <DatePickerInput
                label={context.t(`reports.endInputLabel`)}
                value={moment(tempFilter.between.end).toDate()}
                minDate={moment(tempFilter.between.start).toDate()}
                onChange={(date) => {
                  setTempFilter(prev => ({
                    between: {
                      ...prev.between,
                      end: moment(date).toISOString(),
                    }
                  }));
                  setReportsDate(prev => ({
                    between: {
                      ...prev.between,
                      end: moment(date).format("DD/MM/YYYY"),
                    }
                  }));
                }}
                fullWidth
              />
            </div>
          </Column>
        </Grid>
      </AlertDialog>
    </Grid>
  );
};

export default withStyles(styles)(RepeatAppointment);
