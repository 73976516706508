import React, { useContext, useState, useEffect } from "react";
import AppContext from "../../../context/store";

import { Grid, Column } from "../../../theme/Grid";
import Table from "../../../theme/Table";
import AuthContainer from "../../../components/AuthContainer";
import Axios from "axios";
import ExcelButton from "../../../components/Buttons/ExcelButton";
import RoundedRow from "../../../components/Table/RoundedRow";
import FilterObject from "../../../functions/FilterObject";
import Button from "../../../theme/Button";
import { toast } from "react-toastify";
import { phoneNumberDisplay } from "../../../functions/PhoneNumberDisplay";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";
import AlertDialog from "../../../theme/CustomMUI/AlertDialog";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Input from "../../../theme/CustomMUI/Input";
import { Search } from "@material-ui/icons";
import { position } from "polished";

const PassiveCustomersIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);

  const urlPage = parseInt(
    new URLSearchParams(window.location.search).get("page")
  );

  const [pagination, setPagination] = useState({
    total_page: 1,
    page: urlPage ? urlPage : 1,
    onChange: (page) => setPagination((prev) => ({ ...prev, page: page })),
  });
  const [data, setData] = useState([]);

  const [selectedAllCustomer, setSelectedAllCustomer] = useState(false);

  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteAllConfirm, setDeleteAllConfirm] = useState(false);

  const [search_text, setSearchText] = useState("");

  const getCustomers = () => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: {
        page: pagination.page ? pagination.page : 1,
        is_passive: true,
        extra: 1,
        key: search_text === '' ? null : search_text
      },
    })
      .then(({ data }) => {
        setLoaded(true);
        setData([
          ...data.data.records.map((item) => {
            return {
              ...item,
              phone:
                context.state.user.permission === 2 &&
                  context.state.limited_permission.customer_info === 0
                  ? phoneNumberDisplay(item.phone)
                  : `0${item.phone.substring(0, 3)} ${item.phone.substring(
                    3,
                    6
                  )} ${item.phone.substring(6, 10)}`,
              extra_detail: {
                ...item.extra_detail,
                sum_income: (
                  <RoundedRow color="green">
                    {item.extra_detail.sum_income
                      ? item.extra_detail.sum_income.toFixed(2)
                      : "0.00"}
                    ₺
                  </RoundedRow>
                ),
                total_debt: (
                  <RoundedRow color="red">
                    {item.extra_detail.total_debt
                      ? item.extra_detail.total_debt.toFixed(2)
                      : "0.00"}
                    ₺
                  </RoundedRow>
                ),
              },
            };
          }),
        ]);
        setPagination({
          ...pagination,
          total_page: data.data.records.length,
          //page: data.data.page,
        });
      })
      .catch((e) => {
        console.log(e);
        // if (e.response.status === 401) {
        //   toast.error(e.response.data.message);
        // } else {
        //   toast.warning(e.response.data.message);
        // }
      });
  };

  const makeSelectedCustomersActive = () => {
    Axios.put(`${context.api_endpoint}/company/customer/state`, {
      customer_id: [...selectedCustomers],
    })
      .then((response) => {
        if (response.status === 201) {
          setSelectedCustomers([]);
          toast.success(
            `${context.t(
              `['customers/passive'].selectedCustomersSuccessToast`
            )}`
          );
          getCustomers();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const makeAllCustomersActive = () => {
    Axios.put(`${context.api_endpoint}/company/customer/make/active/multiple`, {
      customer_id: [-1],
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            `${context.t(`['customers/passive'].allCustomersSuccessToast`)}`
          );
          getCustomers();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const makeCustomersDelete = () => {
    Axios.post(`${context.api_endpoint}/company/customer/delete/multiple`, {
      customer_id: [...selectedCustomers],
      is_unscoped: true,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`["customers/passive"].selectedCustomersDeletedSuccessToast`));
          getCustomers(1);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(
            context.t(`["customers/passive"].selectedCustomersDeleteWarnToast`)
          );
          getCustomers(1);
        }
      });
  };

  const makeAllCustomersDelete = async () => {
    await Axios.post(
      `${context.api_endpoint}/company/customer/delete/multiple`,
      {
        customer_id: [-1],
        is_unscoped: true,
      }
    )
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`["customers/passive"].allCustomersDeletedSuccessToast`));
          getCustomers(1);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
          getCustomers(1);
        } else {
          toast.warning(
            context.t(`["customers/passive"].selectedCustomersDeleteWarnToast`)
          );
          getCustomers(1);
        }
      });
  };

  useEffect(() => {
    getCustomers();
  }, [pagination.page]);

  useEffect(() => {
    if (search_text) {
      setTimeout(() => {
        getCustomers();
      }, 500);
    }
  }, [search_text]);

  const tableDataParser = () => {
    const dataParser = {
      id: { label: context.t(`customers.headings.code`) },
      full_name: {
        label: context.t(`customers.headings.fullName`),
        sortable: {
          0: [
            { field: "`customers`.`name`", order: "ASC" },
            { field: "`customers`.`surname`", order: "ASC" },
          ],
          1: [
            { field: "`customers`.`name`", order: "DESC" },
            { field: "`customers`.`surname`", order: "DESC" },
          ],
        },
      },
      phone: {
        label: context.t(`customers.headings.phone`),
        style: { width: 200 },
      },
      sex: { label: context.t(`customers.headings.sex`) },
      "extra_detail.sum_income": {
        label: context.t(`customers.headings.incomeAll`),
        sortable: {
          0: [{ field: "`rp`.`amount`", order: "ASC" }],
          1: [{ field: "`rp`.`amount`", order: "DESC" }],
        },
      },
      "extra_detail.total_debt": {
        label: context.t(`customers.headings.debtAll`),
      },
      _: { label: context.t(`component.actionHeadingText`) },
    };

    return FilterObject(
      dataParser,
      (key, val) =>
        (context.current_flag & context.state.FLAGS.SALON_RANDEVU && true) ||
        (key !== "extra_detail.sum_income" && key !== "extra_detail.total_debt")
    );
  };

  return (
    <AuthContainer authorities={[1]} limited_permission="customer">
      <Grid className="no-gutters-all mb-4">
        <Column className="xs-12 sm-12 md-12 lg-12">
          <Grid spacing={1} style={{
            margin: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            <Column className="xs-12 sm-12 md-12 lg-2" style={{ position: "relative" }}>
              <span
                className="bg-orange"
                style={{
                  position: 'absolute',
                  top: "-2px",
                  right: "5px",
                  zIndex: "11",
                  color: 'white',
                  padding: '2px 7px',
                  borderRadius: '12px',
                  fontSize: '10px',
                  fontWeight: 'bold',
                  textTransform: 'uppercase'
                }}
              >
                {context.t(`appointments.detail.statusNew`)}
              </span>

              <Input
                InputProps={{
                  startAdornment: (
                    <div style={{ marginTop: "10px" }}>
                      <Search />
                    </div>
                  ),
                }}
                placeholder={context.t(`customers.filter.inputLabel`)}
                onChange={async (e) => {
                  const { value } = e.target;
                  setSearchText(value);
                  setPagination((prev) => ({ ...prev, page: 1 }));
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-2">
              <Button
                title={
                  selectedAllCustomer
                    ? context.t(`['customers/risky'].cancelAllButtonTitle`)
                    : context.t(`['customers/risky'].selectedAllButtonTitle`)
                }
                icon={selectedAllCustomer ? "close" : "check"}
                backgroundColor={selectedAllCustomer ? "red" : "primary"}
                textColor="white"
                fullWidth
                onClick={() => {
                  setSelectedAllCustomer(!selectedAllCustomer);
                  if (selectedAllCustomer) {
                    setSelectedCustomers([]);
                  } else {
                    setSelectedCustomers(data?.map((item) => item.id));
                  }
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-2">
              <Button
                title={context.t(`['customers/risky'].deleteSelectedCustomers`)}
                icon="delete"
                backgroundColor="red"
                textColor="white"
                fullWidth
                disabled={selectedCustomers.length === 0}
                onClick={() => setDeleteConfirm(true)}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-2">
              <Button
                title={context.t(`['customers/risky'].deleteAllCustomer`)}
                icon="delete"
                backgroundColor="red"
                textColor="white"
                fullWidth
                disabled={data.length === 0}
                onClick={() => {
                  setDeleteAllConfirm(true);
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-2">
              <Button
                title={context.t(
                  `['customers/passive'].makeActiveButtons.selectedButtonTitle`
                )}
                icon="check"
                backgroundColor="primary"
                textColor="white"
                fullWidth
                disabled={selectedCustomers.length === 0}
                onClick={() => {
                  makeSelectedCustomersActive();
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-12 lg-2">
              <Button
                title={context.t(
                  `['customers/passive'].makeActiveButtons.allButtonTitle`
                )}
                icon="check"
                backgroundColor="primary"
                textColor="white"
                fullWidth
                disabled={data.length === 0}
                onClick={() => {
                  makeAllCustomersActive();
                }}
              />
            </Column>
          </Grid>
          <Grid style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            margin: "0px"
          }}>
            <Column className="xs-12 sm-12 md-12 lg-2" style={{ display: "flex", justifyContent: "flex-end" }}>
              <ExcelButton
                route="customers/passive"
                requestData={{ is_excel: true }}
                size="sm"
              />
            </Column>
          </Grid>
        </Column>
      </Grid >

      <ModalDialog
        open={deleteConfirm}
        title={context.t(`component.CADialog.delete.title`)}
        closeHandler={() => setDeleteConfirm(false)}
        buttons={[
          {
            title: context.t(`component.CADialog.delete.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => makeCustomersDelete(),
          },
          {
            title: context.t(`component.CADialog.delete.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`component.CADialog.delete.alertText`)}</b>
          <br />
          {context.t(`component.CADialog.delete.alertTitleText`)}
        </Alert>
      </ModalDialog>

      <AlertDialog
        open={deleteAllConfirm}
        title={context.t(`component.CADialog.all_delete.title`)}
        closeHandler={() => setDeleteAllConfirm(false)}
        buttons={[
          {
            title: context.t(`component.CADialog.delete.confirmButtonTitle`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            timer: 5,
            onClick: () => makeAllCustomersDelete(),
          },
          {
            title: context.t(`component.CADialog.delete.discardButtonTitle`),
            icon: "close",
            textColor: "grey",
          },
        ]}
      >
        <Alert severity="warning">
          <b>{context.t(`component.CADialog.delete.alertText`)}</b>
          <br />
          {context.t(`component.CADialog.all_delete.alertTitleText`)}
        </Alert>
      </AlertDialog>
      <Grid>
        {!loaded ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "5vh",
              width: "100%",
            }}
          >
            <img
              src={
                process.env.APP_NAME === "salon"
                  ? Loader
                  : process.env.APP_NAME === "en"
                    ? LoaderEN
                    : LoaderSM
              }
              height={70}
            />
          </div>
        ) : (
          <Column className="xs-12 sm-12">
            <Table
              isExist={false}
              loaded={loaded}
              headings={tableDataParser()}
              rows={data}
              replacements={{
                sex: {
                  null: context.t(`customers.replacements.sex.undef`),
                  0: context.t(`customers.replacements.sex.female`),
                  1: context.t(`customers.replacements.sex.male`),
                },
              }}
              pagination={pagination}
              checkedCheckboxes={selectedCustomers}
              checkboxOnChange={(row, state) => {
                if (state) {
                  setSelectedCustomers([...selectedCustomers, row.id]);
                } else {
                  setSelectedCustomers([...selectedCustomers.filter((id) => id !== row.id)]);
                }
              }}
              buttons={[
                {
                  title: context.t(`component.detailEditButtonTitle`),
                  icon: "launch",
                  textColor: "primary",
                  transitionEffect: true,
                  pushEffect: true,
                  onClick: (row) => history.push(`/customers/detail/${row.id}`),
                },
              ]}
            />
          </Column>
        )}
      </Grid>
    </AuthContainer >
  );
};

export default PassiveCustomersIndex;
