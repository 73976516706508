/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useContext, useEffect } from "react";
import Loader from "../../assets/images/loader.gif";
import LoaderEN from "../../assets/images/circular.gif";
import LoaderSM from "../../assets/images/loaderSM.gif";
import { MenuItem } from "@material-ui/core";
import styled from "styled-components";
import AppContext from "../../context/store";
import Axios from "axios";
import { IoSearch } from "react-icons/io5";
import SearchScaffoldInput from "./SearchScaffoldInput";

const CustomerSearchScaffold = (props) => {
  const context = useContext(AppContext);
  // Suggested menu show/hide
  const [open, setOpen] = useState(false);

  // Input end-adornment loader
  const [loading, setLoading] = useState(false);

  // Suggested values
  const [options, setOptions] = useState();

  // Posting to async request
  const [keyword, setKeyword] = useState("");


  // Handling this
  const [selected, setSelected] = useState(
    props.selected ? props.selected : null
  );

  // timeout
  const [debouncedValue, setDebouncedValue] = useState('')

  // Input value
  const [input, setInput] = useState(null);

  const getCustomers = (key) => {
    Axios.get(`${context.api_endpoint}/company/customers`, {
      params: { key: key ? key : " " },
    }).then(async ({ data }) => {
      if (props.withPhone) {
        setOptions(
          [...data?.data?.records].map((item) => {
            return {
              id: item.id,
              full_name: `${item.full_name} (*** ${item.phone.slice(6, 11)})`,
              send_sms: item.send_sms,
              description: item.description,
            };
          })
        )
      }
      else {
        setOptions([...data?.data?.records]);
      }
    });
  };


  useEffect(() => {
    if (debouncedValue !== null) {
      getCustomers(debouncedValue)
    }
  }, [debouncedValue]);

  useEffect(() => {
    setLoading(true)
    const handler = setTimeout(() => {
      if (keyword !== null &&
        (selected && selected.hasOwnProperty(props.labelKey)
          ? selected[props.labelKey] !== keyword
            ? true
            : false
          : true)) {
        setDebouncedValue(keyword);
        setLoading(false)
      }
    }, 800);
    return () => {
      clearTimeout(handler);
    };
  }, [keyword])

  useEffect(() => {
    selected
      ? props.selectedHandler(
        props.returnFullObject ? selected : selected[props.valueKey]
      )
      : setKeyword("");
  }, [selected]);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    window.addEventListener("click", () => {
      setOpen(false);
    });
  }, []);

  let typingTimeout = null;

  return (
    <AutocompleteContainer>
      <SearchScaffoldInput
        required={props.required}
        style={{
          ...props.style,
        }}
        className="mb-0"
        label={props.label}
        helperText={props.helperText}
        disabled={props.disabled}
        hoverHelperText={props.hoverHelperText}
        placeholder={props.placeholder}
        value={`${selected?.[props.labelKey] ?? input ?? props.value ?? ""}`}
        InputProps={{
          style: {
            height: "48px",
            borderRadius: "30px",
            display: "flex",
            alignItems: "center",
            zIndex: 1200
          },
          classes: {
            input: "custom-input-class",
          },
          onClick: (e) => {
            e.stopPropagation();
            getCustomers(keyword);
            setOpen(true);
          },
          onChange: (e) => {
            setInput(e.target.value);
            setKeyword(e.target.value);
            setOpen(true);
          },
          onFocus: () => {
            setOpen(true);
          },
          startAdornment: (<div style={{
            display: "flex",
            alignItems: "center",
            marginRight: "8px",
            color: "#666",
            height: "100%"
          }}>
            <IoSearch style={{ fontSize: "20px" }} />
          </div>),
          endAdornment: (
            <React.Fragment>
              {selected || props.value ? (
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    height: "100%"
                  }}
                  onClick={async () => {
                    await setKeyword("");
                    await setInput("");
                    await setSelected(null);
                    await props.onRemove();
                  }}
                  className="material-icons"
                >
                  close
                </span>
              ) : loading ? (
                <img
                  src={
                    process.env.APP_NAME === "salon"
                      ? Loader
                      : process.env.APP_NAME === "en"
                        ? LoaderEN
                        : LoaderSM
                  }
                  style={{
                    margin: '0px',
                    padding: '0px',
                    height: '24px',
                    width: '24px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                  alt="loading"
                />
              ) : null}
            </React.Fragment>
          ),
        }}
      />
      {open && !props.disabled ? (
        <OptionsContainer
          hasHelperText={props.helperText !== undefined ? true : false}
          style={props.opcStyle}
          onClick={(e) => e.stopPropagation()}
        >
          {options &&
            options.length > 0 &&
            options.map((option, index) => (
              <MenuItem
                key={index}
                onClick={async () => {
                  setSelected(option);
                  setOpen(false);
                  setKeyword(option[props.labelKey]);
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {props.extraLimit ? (
                    <p>{`${option[props.labelKey]} (${option[props.extraLimit]
                      })`}</p>
                  ) : (
                    <p>{option[props.labelKey]}</p>
                  )}
                  <p>
                    {props.showField
                      ? `${Math.round(option[props.showField])} ${context.state.currency ? context.state.currency : "₺"
                      }`
                      : ""}
                  </p>
                </div>
              </MenuItem>
            ))}
        </OptionsContainer>
      ) : null}
    </AutocompleteContainer>
  );
};

export default React.memo(CustomerSearchScaffold);

const AutocompleteContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 8px 0;

  .MuiInputBase-root {
    height: 40px;
    border-radius: 20px;
    background-color:rgb(255, 255, 255);
    padding: 0 16px;
    border: none;
    box-shadow: 0 1px 3px rgba(0,0,0,0.01);
    display: flex;
    align-items: center;
  }

  .MuiInputBase-input {
    height: 40px !important;
    line-height: 40px !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
  }

  .custom-input-class {
    height: 40px !important;
    line-height: 40px !important;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
  }

  input {
    height: 40px !important;
    line-height: 40px !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  .material-icons {
    display: flex;
    align-items: center;
    height: 100%;
  }

  img {
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    width: 24px !important;
  }
`;

const OptionsContainer = styled.div`
  width: 100%;
  background: #fff;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 4px;
  padding: 8px 0;
  border-radius: 10px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.06);
  position: absolute;
  top: 100%;
`;
