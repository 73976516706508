import React, { useContext, useEffect } from "react";
import { MdCampaign } from "react-icons/md";
import styled from "styled-components";
import AppContext from "../../../../context/store";

function Time({ timeLeft, url, setTimeLeft }) {
  const context = useContext(AppContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Pad numbers with leading zeros if necessary
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return (
      <ButtonContainer
        className="campaigns_timeleft_btn"
        onClick={() => window.open(url)}
      >
        <MdCampaign style={{ color: "white" }} size={35} />
        <div>
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              whiteSpace: "nowrap",
              color: "white",
              fontWeight: "bold",
            }}
          >
            {/* {process.env.APP_NAME !== "management"
              ? "İlk Alım Fırsatı!"
              : "First Buy Opportunity!"} */}
              {context.t("dashboard.packageTitle")}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "3px",
              color: "white",
              fontSize: "15px",
            }}
          >
            <div>
              <span>{formattedHours}</span>:<span>{formattedMinutes}</span>:
              <span>{formattedSeconds}</span>
            </div>
          </div>
        </div>
      </ButtonContainer>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        marginTop: "5px",
      }}
    >
      {formatTime(timeLeft)}
    </div>
  );
}

export default Time;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 75px;
  right: 160px;
  width: 200px;
  font-family: Arial, sans-serif;
  padding-right: 12px;
  padding-left: 12px;
  z-index: 9999998;
  text-align: center;
  background-color: #ff0000;
  border-radius: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;

  @media (max-width: 1500px) {
    bottom: 75px;
    right: 160px;
    width: 200px;
    padding-right: 5px;
    padding-left: 5px;
    gap: 10px;
  }
  
  @media (max-width: 900px) {
    bottom: 75px;
    right: 160px;
    width: 200px;
    padding-right: 8px;
    padding-left: 8px;
    gap: 10px;
  }
  
  @media (max-width: 768px) {
    bottom: 75px;
    right: 160px;
    width: 200px;
    padding-right: 8px;
    padding-left: 8px;
    gap: 10px;
  }
  
  @media (max-width: 480px) {
    bottom: 75px;
    right: 160px;
    width: 200px;
    padding-right: 6px;
    padding-left: 6px;
    gap: 5px;
    border-radius: 20px;
  }
`;
