import React, { useEffect, useContext } from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";
import { RxLapTimer } from "react-icons/rx";
import { MuiThemeProvider } from "@material-ui/core/styles";
import AppContext from "../../../../context/store";
import { MdCampaign } from "react-icons/md";
import { LuMousePointerClick } from "react-icons/lu";

const theme = {
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "14px",
        color: "primary",
        backgroundColor: "white",
        textAlign: "center",
      },
    },
  },
};

function TimeLeftRenewal({ timeLeft, url, setTimeLeft }) {
  const context = useContext(AppContext);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeft > 0) {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft]);

  function formatTime(seconds) {
    const days = Math.floor(seconds / 86400); // 1 gün = 86400 saniye
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Sayıları gerekli durumlarda önde sıfır olacak şekilde pad etme
    const formattedDays = days.toString();
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    // console.log("formattedDays: ", formattedDays);
    // console.log("formattedMinutes: ", formattedMinutes);
    // console.log("formattedSeconds: ", formattedSeconds);

    return (
      <MuiThemeProvider theme={theme}>
        <Tooltip
          arrow
          color="red"
          title={
            <div>
              {new Date() <
                new Date(context.state?.company_license?.end_date) ? (
                <>
                  {process.env.APP_NAME !== "management"
                    ? "Lisans süreniz sona ermek üzere! Size özel lisans fiyatlarından yararlanmak için"
                    : "Your license is about to expire! Special license for you to benefit from the prices"}

                  <br /><strong>
                    {process.env.APP_NAME !== "management"
                      ? "Kalan Süreniz:"
                      : "Remaining Time:"}
                  </strong>
                  {Number(formattedDays) >= 1 ? (
                    <>
                      {process.env.APP_NAME !== "management" ? "Son" : "Last"}
                      <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {formattedDays}
                      </span>
                      {process.env.APP_NAME !== "management" ? "Gün" : "Day"}
                    </>
                  ) : (
                    <>
                      <span style={{ fontWeight: "bold" }}>{hours}</span>:
                      <span style={{ fontWeight: "bold" }}>
                        {formattedMinutes}
                      </span>
                      :
                      <span style={{ fontWeight: "bold" }}>
                        {formattedSeconds}
                      </span>
                    </>
                  )}
                </>
              ) : (
                <>
                  {process.env.APP_NAME !== "management"
                    ? "Lisans süreniz sona ermek üzere! Size özel lisans fiyatlarından yararlanmak için"
                    : "Your license is about to expire! Special license for you to benefit from the prices"}

                  <strong>
                    {process.env.APP_NAME !== "management"
                      ? "Kalan Süreniz:"
                      : "Remaining Time:"}
                  </strong>
                  {Number(formattedDays) >= 1 ? (
                    <>
                      {process.env.APP_NAME !== "management" ? "Son " : "Last "}
                      <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {formattedDays}
                      </span>
                      {process.env.APP_NAME !== "management" ? " Gün" : " Day"}
                    </>
                  ) : (
                    <>
                      <span style={{ fontWeight: "bold" }}>{hours}</span>:
                      <span style={{ fontWeight: "bold" }}>
                        {formattedMinutes}
                      </span>
                      :
                      <span style={{ fontWeight: "bold" }}>
                        {formattedSeconds}
                      </span>
                    </>
                  )}
                </>
              )}
            </div>
          }
        >
          <ButtonContainer
            className="campaigns_timeleft_btn"
            onClick={() => window.open(url)}
          >
            {new Date() < new Date(context.state?.company_license?.end_date) ? (
              <RxLapTimer style={{ color: "white" }} size={30} />
            ) : (
              <MdCampaign style={{ color: "white" }} size={40} />
            )}
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "2px",
                  color: "white",
                  fontSize:
                    new Date() <
                      new Date(context.state?.company_license?.end_date)
                      ? "15px"
                      : "13px",
                }}
              >
                <div style={{ width: "100%", textAlign: "center" }}>
                  {new Date() <
                    new Date(context.state?.company_license?.end_date) ? (
                    Number(formattedDays) >= 1 ? (
                      <>
                        {context.t(`licenseDetails.lastDate`).split("%s").map((part, index) => (
                          <React.Fragment key={index}>
                            {part}
                            {index === 0 && (
                              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                                {formattedDays}
                              </span>
                            )}
                          </React.Fragment>
                        ))}
                      </>
                    ) : (
                      <>
                        <span style={{ fontWeight: "bold" }}>{hours}</span>:
                        <span style={{ fontWeight: "bold" }}>
                          {formattedMinutes}
                        </span>
                        :
                        <span style={{ fontWeight: "bold" }}>
                          {formattedSeconds}
                        </span>
                      </>
                    )
                  ) : (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {" "}
                      <div>{context.t(`licenseDetails.renewalOpportunity`)}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <LuMousePointerClick style={{ color: "white" }} size={25} />
          </ButtonContainer>
        </Tooltip>
      </MuiThemeProvider>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        marginTop: "5px",
      }}
    >
      {formatTime(timeLeft)}
    </div>
  );
}

export default TimeLeftRenewal;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 80px;
  right: 160px;
  width: 190px;
  font-family: Arial, sans-serif;
  padding: 5px;
  z-index: 999;
  text-align: center;
  background-color: #ff0000;
  border-radius: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  @media (max-width: 1800px) {
    position: fixed;
    bottom: 80px;
    right: 160px;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }

  @media (max-width: 1500px) {
    position: fixed;
    bottom: 80px;
    right: 160px;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }
  @media (max-width: 900px) {
    position: fixed;
    bottom: 80px;
    right: 160px;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }
  @media (max-width: 768px) {
    position: fixed;
    bottom: 80px;
    right: 160px;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 10px;
  }
  @media (max-width: 480px) {
    position: fixed;
    bottom: 80px;
    right: 160px;
    width: 160px; /* Adjust width to 100% for smaller screens */
    padding: 5px;
    gap: 5px;
    border-radius: 20px;
  }
`;
