import React from "react";
import TextField from "@material-ui/core/TextField";
import HoverHelperText from "../HoverHelperText";

const SearchScaffoldInput = (props) => {
  return (
    <div
      className={props.className ? props.className : "mb-2"}
      style={props.style}
    >
      {props.hoverHelperText && (
        <HoverHelperText hoverHelperText={props.hoverHelperText} />
      )}
      <TextField
        {...props}
        required={props.required}
        style={props.style}
        type={props.type}
        label={props.label}
        readOnly={props.readOnly}
        disabled={props.disabled}
        value={props.value}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder}
        helperText={props.helperText}
        multiline={props.multiline}
        rows={props.rows}
        variant={props.variant ?? "outlined"}
        InputProps={{
          ...props.InputProps,
          autoComplete:
            props.autoComplete === undefined ? "text" : "new-password",
        }}
        onChange={props.onChange ? (e) => props.onChange(e) : null}
        fullWidth
      />
    </div>
  );
};

export default React.memo(SearchScaffoldInput);
