import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import CardSection from "../../../components/Cards/CardSection";

import BetweenTimeGenerator from "../../../functions/BetweenTimeGenerator";

import styled from "styled-components";
import { Column, Grid } from "../../../theme/Grid";
import Input from "../../../theme/CustomMUI/Input";
import Select from "../../../theme/CustomMUI/Select";
import Button from "../../../theme/Button";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Axios from "axios";
import { ButtonBase, FormControlLabel, Switch } from "@material-ui/core";
import { toast } from "react-toastify";
import { Alert } from "@material-ui/lab";
import Validate from "../../../functions/Validate";
import FormDataGenerator from "../../../functions/FormDataGenerator";
import CompressImage from "../../../functions/CompressImage";
import FCLabel from "../../../theme/FCLabel";
import CSwitch from "../../../theme/CustomMUI/CSwitch";
import MenuIconButton from "../../../components/Scaffold/MenuIconButton";
import { ExitToApp } from "@material-ui/icons";
import { flushSync } from "react-dom";
import TranslateView from "../../../components/Scaffold/TranslateView";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import AuthWithToken from "../../../functions/AuthWithToken";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Loader from "../../../assets/images/loader.gif";
import LoaderEN from "../../../assets/images/circular.gif";
import LoaderSM from "../../../assets/images/loaderSM.gif";
import LaunchIcon from "@material-ui/icons/Launch";
import DialogTitle from "@material-ui/core/DialogTitle";
import EmpoolesPackages from "../../../components/EmpoolesPackages";
import ModalDialog from "../../../theme/CustomMUI/AlertDialog";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PhoneInput from "react-phone-input-2";
import { borderColor } from "polished";

const ProfileIndex = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [packets, setPackets] = useState([]);
  const [selectedDay, setSelectedDay] = useState(1);
  const [loaded, setLoaded] = useState(false);

  const [generatedHours, setGeneratedHours] = useState(
    BetweenTimeGenerator({
      ...context.state.company_shift,
    })
  );

  const [categories, setCategories] = useState([]);
  const [companyServices, setCompanyServices] = useState([]);

  const [categoryId, setCategoryId] = useState(0);
  const [categoryOpen, setCategoryOpen] = useState([
    {
      id: 0,
      inAcvtive: false,
    },
  ]);

  const [employee, setEmployee] = useState({
    color: "",
    id: null,
    name: null,
    surname: null,
    sex: null,
    password: null,
    send_sms: null,
    detail: {
      profile_photo: null,
      job_title: null,
      bounty: null,
      dialCode: null,
      phone: null,
      mail: null,
    },
    permission: 0,
    services: [],
    service_ids: [],
    appointments: {
      1: { active: true, hours: [] },
      2: { active: true, hours: [] },
      3: { active: true, hours: [] },
      4: { active: true, hours: [] },
      5: { active: true, hours: [] },
      6: { active: true, hours: [] },
      7: { active: true, hours: [] },
    },
    closed_appointments: {
      1: { active: true, hours: [] },
      2: { active: true, hours: [] },
      3: { active: true, hours: [] },
      4: { active: true, hours: [] },
      5: { active: true, hours: [] },
      6: { active: true, hours: [] },
      7: { active: true, hours: [] },
    },
    limited_perm: {
      id: null,
      appointment: 2,
      sales: 2,
      customer: 2,
      customer_info: 2,
      services: 2,
      sms: 2,
      income_expense: 2,
      wallet: 2,
      gallery: 2,
      system: 2,
    },
  });

  const [selectLanguage, setSelectLanguage] = useState(false);
  const [lang, setLang] = useState(context.state.default_locale);
  const [branchesData, setBranchesData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [branchDialog, setBranchDialog] = useState(false);
  const [selectedAgainServiceModal, setSelectedAgainServiceModal] =
    useState(false);
  const [selectedAgainServiceList, setSelectedAgainServiceList] = useState([]);
  const [lastServicesIds, setLastServicesIds] = useState([]);

  const getSingleStaff = () => {
    Axios.get(`${context.api_endpoint}/company/staff/${context.state.user.id}`)
      .then(({ data }) => {
        flushSync(() => {
          setLoaded(true);
          setPackets([...data?.data?.staff_packets.map((m) => m.packet_id)]);
          setCompanyServices([
            ...data.data.company_services.map((service, i) => {
              if (
                service.category_name &&
                service.category_name.toLowerCase().includes("erkek")
              ) {
                service.name += " (E)";
              } else if (
                service.category_name &&
                service.category_name.toLowerCase().includes("kadın")
              ) {
                service.name += " (K)";
              }
              return service;
            }),
          ]);
          setEmployee({
            ...data.data,
            color: data.data.color,
            service_ids: [...data.data.services].map(
              (s) => s.service && s.service.id
            ),
            closed_appointments:
              data.data.closed_appointments.un_appointments &&
                data.data.closed_appointments.un_appointments !== ""
                ? JSON.parse(data.data.closed_appointments.un_appointments)
                : {
                  1: { active: true, hours: [] },
                  2: { active: true, hours: [] },
                  3: { active: true, hours: [] },
                  4: { active: true, hours: [] },
                  5: { active: true, hours: [] },
                  6: { active: true, hours: [] },
                  7: { active: true, hours: [] },
                },
            services: [...data.data.services]
              .map((s) => (s.service ? s.service.id ?? undefined : undefined))
              .filter((item) => item !== undefined),
            appointments:
              data.data.closed_appointments.un_appointments &&
                data.data.closed_appointments.un_appointments !== ""
                ? JSON.parse(data.data.closed_appointments.un_appointments)
                : {
                  1: { active: true, hours: [] },
                  2: { active: true, hours: [] },
                  3: { active: true, hours: [] },
                  4: { active: true, hours: [] },
                  5: { active: true, hours: [] },
                  6: { active: true, hours: [] },
                  7: { active: true, hours: [] },
                },
          });
        });
        if (data.data.limited_perm === null) {
          setEmployee({
            ...employee,
            limited_perm: {
              id: null,
              appointment: 2,
              sales: 2,
              customer: 2,
              customer_info: 2,
              services: 2,
              sms: 2,
              income_expense: 2,
              wallet: 2,
              gallery: 2,
              system: 2,
            },
          });
        }
      })
      .catch((e) => {
        if (e.response.status === 204) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const uploadPhotoSingleStaff = async (e) => {
    Axios.post(
      `${context.api_endpoint}/company/staff/update/${context.state.user.id}/photo`,
      FormDataGenerator({
        file: await CompressImage(e.target.files[0], {
          maxSizeMB: 2,
          fileType: "image/jpg",
        }),
      }),
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`profile.photoUploadToast`));
          context.dispatch({
            type: "LOGIN_USER",
            payload: { user: { profile_photo: response.data.data } },
          });
          getSingleStaff();
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const deletePhotoSingleStaff = () => {
    Axios.delete(
      `${context.api_endpoint}/company/staff/delete/${context.state.user.id}/photo`
    )
      .then((response) => {
        if (response.status === 201) {
          toast.success(context.t(`profile.photoDeleteToast`));
          context.dispatch({
            type: "LOGIN_USER",
            payload: { user: { profile_photo: "" } },
          });
          setEmployee({
            ...employee,
            detail: {
              ...employee.detail,
              profile_photo: "",
            },
          });
          setLoaded(false);
          getSingleStaff();
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const updateSingleStaff = () => {
    validateStaff() &&
      Axios.put(
        `${context.api_endpoint}/company/staff/update/${context.state.user.id}`,
        {
          ...employee,
          company_services: undefined,
          packet_ids: packets,
        }
      )
        .then((response) => {
          if (response.status === 201) {
            setSelectedAgainServiceList(response.data.data.map((m) => m));
            setEmployee({
              ...employee,
              service_ids: [
                ...employee.service_ids,
                ...response.data.data.map((m) => m.id),
              ],
            });
            setSelectedAgainServiceModal(true);
          } else if (response.status === 200) {
            toast.success(context.t(`profile.updateToast`));
          } else {
            toast.error("Something went wrong!");
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        });
  };

  const deleteSingleStaff = () => {
    Axios.delete(
      `${context.api_endpoint}/company/staff/delete/${context.state.user.id}`
    ).then((response) => {
      if (response.status === 2000) {
        toast.success(context.t(`employees.deleteStaffSuccessToast`));
        history.push("/employees");
      }
    });
  };
  // update_packets
  const updateState = (newStateValue) => {
    setPackets(newStateValue);
  };

  const handleChange = (event, label) => {
    setEmployee({
      ...employee,
      limited_perm: {
        ...employee.limited_perm,
        [label]: parseInt(event.target.value),
      },
    });
  };

  const handleCategoryClick = (category) => {
    setCategoryId(category);
    setCategoryOpen([
      ...categoryOpen.map((c) => {
        return {
          ...c,
          id: category,
          open: true,
        };
      }),
    ]);
  };

  const tryLogout = async () => {
    await context.dispatch({ type: "LOGOUT_USER" });
    await history.push("/");
    localStorage.removeItem("state2");
  };

  const getServices = () => {
    Axios.get(
      `${context.api_endpoint}/company/services/with/category/all`
    ).then(async ({ data }) => {
      setCategories([...data.data.filter((m) => m.services.length !== 0)]);
    });
  };

  const changeBranch = (id) => {
    setSpinner(true);
    Axios.get(`${context.api_endpoint}/company/loginbranch/${id}`)
      .then((data) => {
        // gelen token ile birlikte contextin objesi yenileniyor.
        AuthWithToken(data.data.data.token, context);
        history.push("/");
        toast.success(context.t(`profile.detail.branch_change_alert`));
      })
      .catch((e) => {
        console.error(e.message);
        toast.error("operation failed");
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const getBranches = () => {
    Axios.get(`${context.api_endpoint}/company/branches`)
      .then((response) => {
        setBranchesData([...response.data.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSingleStaff();
    getServices();
    window.addEventListener("click", (e) => {
      if (e.target.id !== "languageBox") {
        setSelectLanguage(false);
      }
    });
    getBranches();
  }, []);

  const changeHourStatus = (hour) => {
    !employee.closed_appointments[selectedDay].hours.includes(hour)
      ? setEmployee({
        ...employee,
        closed_appointments: {
          ...employee.closed_appointments,
          [selectedDay]: {
            ...employee.closed_appointments[selectedDay],
            hours: [...employee.closed_appointments[selectedDay].hours, hour],
          },
        },
      })
      : setEmployee({
        ...employee,
        closed_appointments: {
          ...employee.closed_appointments,
          [selectedDay]: {
            ...employee.closed_appointments[selectedDay],
            hours: [
              ...employee.closed_appointments[selectedDay].hours.filter(
                (item) => item !== hour
              ),
            ],
          },
        },
      });
  };

  const changeDayStatus = () => {
    setEmployee({
      ...employee,
      closed_appointments: {
        ...employee.closed_appointments,
        [selectedDay]: {
          ...employee.closed_appointments[selectedDay],
          active: !employee.closed_appointments[selectedDay].active,
        },
      },
    });
  };

  const changeServiceStatus = (service_id) => {
    employee.services.includes(service_id)
      ? setEmployee({
        ...employee,
        services: employee.services.filter((s_id) => service_id !== s_id),
      })
      : setEmployee({
        ...employee,
        services: [...employee.services, service_id],
      });
  };

  const validateStaff = () => {
    return Validate([
      {
        field: "İsim",
        value: employee.name,
        condition: /[A-Za-z]+/,
      },
      {
        field: "Soyisim",
        value: employee.surname,
        condition: /[A-Za-z]+/,
      },
      {
        field: "Cinsiyet",
        value: employee.sex,
        condition: Number,
      },
      {
        field: "Cep Telefonu",
        value: employee.detail.phone,
        condition: /\S{10}/,
      },
      {
        field: "E-Posta Adresi",
        value: employee.detail.mail,
        condition: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/,
      },
    ]);
  };

  return !spinner ? (
    <AuthContainer>
      <Grid>
        <Column
          className="xs-12 mb-3"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          {loaded ? (
            <ProfilePhoto
              src={
                employee.detail.profile_photo
                  ? `${context.api_endpoint.replace("api/v1", "")}${context.state.company_id
                  }/${employee.detail.profile_photo}`
                  : require("../../../assets/images/profile_photo.svg")
              }
            />
          ) : (
            <div
              style={{ width: 160, height: 160, borderRadius: 5 }}
              className="skeleton"
            />
          )}
          <input
            id="profile_photo"
            style={{ display: "none" }}
            value=""
            type="file"
            accept="image/*"
            onChange={(e) => uploadPhotoSingleStaff(e)}
          />

          <Button
            className="mt-1"
            icon="add_photo_alternate"
            title={context.t(`profile.photo.uploadButtonTitle`)}
            textColor="blue"
            backgroundColor="blue-opacity"
            fullWidth
            onClick={() => document.getElementById("profile_photo").click()}
          />
          {employee.detail.profile_photo && (
            <Button
              className="mt-1"
              icon="delete"
              title={context.t(`profile.photo.deleteButtonTitle`)}
              textColor="red"
              backgroundColor="red-opacity"
              fullWidth
              onClick={() => deletePhotoSingleStaff()}
            />
          )}

          <LanguageSelective
            id="languageBox"
            onClick={() => {
              setSelectLanguage(!selectLanguage);
            }}
          >
            <Flag src={require(`../../../assets/images/flags/${lang}.svg`)} />
            {lang === "tr" && "Türkçe"}
            {lang === "en" && "English"}
            {lang === "ru" && "Russia"}
            {lang === "ar" && "Arabic"}
          </LanguageSelective>
          <TranslateView
            state={selectLanguage}
            closeHandler={() => {
              setSelectLanguage(false);
            }}
            stopPropagation={(e) => e.stopPropagation()}
          />

          {!context.state.is_dealer && context.state.user.permission === 1 && (
            <Button
              className="mt-1"
              icon="launch"
              title={context.t(`profile.detail.branch_change`)}
              textColor="blue"
              backgroundColor="blue-opacity"
              fullWidth
              onClick={() => setBranchDialog(true)}
            />
          )}
          <Dialog onClose={() => setBranchDialog(false)} open={branchDialog}>
            <DialogTitle id="simple-dialog-title">
              {context.t(`profile.detail.branch_list`)}
            </DialogTitle>
            <List className="branch_list">
              {branchesData.map((m) => (
                <ListItem
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#F5F5F5",
                    margin: "8px",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={m.id}
                  value={m}
                  onClick={() => { }}
                >
                  {m.name}
                  <button
                    onClick={() => {
                      changeBranch(m.staffs[0].id);
                      setBranchDialog(false);
                    }}
                    className="enter_to_branch_btn"
                  >
                    <LaunchIcon fontSize="small" />
                    {context.t(`profile.detail.branch_enter`)}
                  </button>
                </ListItem>
              ))}
            </List>
          </Dialog>
        </Column>

        <Column className="col-auto xs-12 sm-12 md-12 lg-12 xl-12">
          <CardSection
            title={
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                {context.t(`profile.detail.profileTitle`)}
                <div style={{ cursor: "pointer" }} onClick={() => tryLogout()}>
                  <MenuIconButton
                    icon={ExitToApp}
                    iconHeight={24}
                    style={{ padding: "0px" }}
                    className="fc-grey"
                    onClick={(e) => { }}
                  />
                  <span style={{ fontSize: "16px", fontWeight: "normal" }}>
                    {context.t("profile.logoutButtonTitle")}
                  </span>
                </div>
              </div>
            }
          >
            <Column className="xs-12 sm-12 md-4">
              <Input
                required
                label={context.t(`profile.detail.nameInputLabel`)}
                value={employee.name !== null ? employee.name : ""}
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    name: e.target.value,
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-4">
              <Input
                required
                label={context.t(`profile.detail.surnameInputLabel`)}
                value={employee.surname !== null ? employee.surname : ""}
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    surname: e.target.value,
                  });
                }}
              />
            </Column>
            <Column className="xs-12 sm-12 md-4">
              <CSwitch
                title={context.t(`profile.detail.genderInputLabel`)}
                label={context.t(`profile.detail.genderMale`)}
                labelLeft={context.t(`profile.detail.genderFemale`)}
                checked={!!employee.sex}
                color="primary"
                closeColor="purple"
                onChange={(checked) => {
                  setEmployee({
                    ...employee,
                    sex: checked.target.checked === false ? 0 : 1,
                  });
                }}
              />
              {/* <Select
                required
                label={context.t(`profile.detail.genderInputLabel`)}
                items={[
                  {
                    value: "0",
                    name: context.t(`profile.detail.genderFemale`),
                  },
                  {
                    value: "1",
                    name: context.t(`profile.detail.genderMale`),
                  },
                ]}
                selected={employee.sex !== null ? `${employee.sex}` : ""}
                labelKey="name"
                valueKey="value"
                handler={(sex) =>
                  setEmployee({
                    ...employee,
                    sex: parseInt(sex)
                  })
                }
              /> */}
            </Column>

            <Column
              className={
                (context.current_flag & context.state.FLAGS.SALON_RANDEVU &&
                  "xs-12 sm-12 md-4") ||
                "xs-12 sm-12 md-12"
              }
            >
              <Input
                required
                label={context.t(`profile.detail.jobTitleInputLabel`)}
                value={
                  employee.detail.job_title !== null
                    ? employee.detail.job_title
                    : ""
                }
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    detail: {
                      ...employee.detail,
                      job_title: e.target.value,
                    },
                  });
                }}
              />
            </Column>

            {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
              <Column className="xs-12 sm-12 md-4">
                <MaskedInput
                  label={context.t(`profile.detail.bountyInputLabel`)}
                  mask="99"
                  maskChar=""
                  value={
                    employee.detail.bounty !== null
                      ? employee.detail.bounty
                      : ""
                  }
                  onChange={(e) => {
                    setEmployee({
                      ...employee,
                      detail: {
                        ...employee.detail,
                        bounty:
                          e.target.value.length > 0
                            ? parseInt(e.target.value)
                            : null,
                      },
                    });
                  }}
                />
              </Column>
            )) ||
              null}

            <Column className="xs-12 sm-12 md-4">
              <CSwitch
                title={context.t(`profile.detail.sendSmsInputLabel`)}
                label={`${context.t(`profile.detail.sendSmsFalse`)}/${context.t(
                  `profile.detail.sendSmsTrue`
                )}`}
                checked={!!employee.send_sms}
                color="primary"
                onChange={(checked) => {
                  setEmployee({
                    ...employee,
                    send_sms: checked.target.checked === false ? false : true,
                  });
                }}
              />
              {/* <Select
                required
                label={context.t(`profile.detail.sendSmsInputLabel`)}
                items={[
                  {
                    value: true,
                    label: context.t(`profile.detail.sendSmsTrue`),
                  },
                  {
                    value: false,
                    label: context.t(`profile.detail.sendSmsFalse`),
                  },
                ]}
                selected={
                  employee.send_sms !== null ? `${employee.send_sms}` : ""
                }
                labelKey="label"
                valueKey="value"
                handler={(send_sms) =>
                  setEmployee({
                    ...employee,
                    send_sms: send_sms === "true",
                  })
                }
              /> */}
            </Column>
          </CardSection>

          <CardSection
            title={context.t(`profile.contact.title`)}
            className="mt-5"
          >
            <Column className="xs-12 sm-12 md-4">
              <Input
                required
                label={context.t(`profile.contact.mailInputLabel`)}
                value={
                  employee.detail.mail !== null ? employee.detail.mail : ""
                }
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    detail: {
                      ...employee.detail,
                      mail: e.target.value,
                    },
                  });
                }}
              />
            </Column>

            <Column className="xs-12 sm-12 md-4">
              {process.env.APP_NAME === "management" ? (
                <PhoneInput
                  required
                  label={context.t(`profile.contact.phoneInputLabel`)}
                  className="input-phone-number"
                  inputStyle={{ border: "none", width: "100%", height: "70%", boxShadow: "0px 0px 4px 0px #f9f9f9", borderRadius: "4px" }}
                  containerStyle={{ marginBottom: "13px" }}
                  value={employee.detail.dialCode ? employee.detail.dialCode + employee.detail.phone : ''}
                  onChange={(phone, data) => {
                    const rawPhone = phone.slice(data.dialCode.length);
                    setEmployee({
                      ...employee,
                      detail: {
                        ...employee.detail,
                        dialCode: `+${data.dialCode}`,
                        phone: rawPhone
                      }
                    });
                  }}
                />
              ) : (
                <MaskedInput
                  required
                  label={context.t(`profile.contact.phoneInputLabel`)}
                  placeholder="500 000 0000"
                  mask="999 999 9999"
                  maskChar=""
                  value={
                    employee.detail.phone !== null ? employee.detail.phone : ""
                  }
                  startAdornment={employee.detail.dialCode}
                  onChange={(e) => {
                    setEmployee({
                      ...employee,
                      detail: {
                        ...employee.detail,
                        phone:
                          e.target.value && e.target.value.substring(0, 1) === "5"
                            ? e.target.value.replace(/\s/g, "")
                            : null,
                      },
                    });
                  }}
                />
              )}
            </Column>
            <Column className="xs-12 sm-12 md-4">
              <Input
                label={context.t(`profile.contact.newPasswordInputLabel`)}
                autoComplete={false}
                type="password"
                onChange={(e) => {
                  setEmployee({
                    ...employee,
                    password: e.target.value,
                  });
                }}
              />
            </Column>
          </CardSection>

          <CardSection
            title={context.t(`profile.services.title`)}
            className="mt-5"
          >
            <Column className="xs-12 sm-12 mb-3">
              <Alert severity="info">
                <b>{context.t(`profile.services.alertBoldText`)}</b>
                <br />
                {context.t(`profile.services.alertText`)}
              </Alert>
            </Column>
            <Column className="xs-12 sm-12">
              <Grid>
                {loaded === true &&
                  categories.map((category, index) => {
                    return (
                      <Column
                        key={index}
                        className="col xs-12 sm-12 md-12 lg-12 xl-12"
                      >
                        <div
                          onClick={() => {
                            handleCategoryClick(category.id);
                          }}
                          className={
                            categoryId === category.id
                              ? "mb-2 category-container active-category"
                              : "mb-2 category-container"
                          }
                        >
                          <span>{category.name}</span>
                        </div>
                        {category.services.map((service) => {
                          return (
                            <>
                              {categoryOpen.map((category_open, index) => {
                                if (
                                  category_open.id === category.id &&
                                  category_open.open === true
                                ) {
                                  return (
                                    <Column className="col xs-12 sm-12 md-6 lg-6 xl-6">
                                      <div
                                        key={index}
                                        style={{
                                          cursor: "pointer",
                                          border: `1px solid ${employee.services.includes(
                                            service.id
                                          )
                                            ? "#3189ec"
                                            : "white"
                                            }`,
                                          borderRadius: "5px",
                                          marginBottom: "12px",
                                          padding: "10px 0px",
                                          width: "100%",
                                          color: `${employee.services.includes(
                                            service.id
                                          )
                                            ? "#3189ec"
                                            : "black"
                                            }`,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                        }}
                                        onClick={() =>
                                          changeServiceStatus(service.id)
                                        }
                                      >
                                        {employee.services.includes(
                                          service.id
                                        ) ? (
                                          <CheckIcon
                                            fontSize="small"
                                            style={{ paddingRight: "5px" }}
                                          />
                                        ) : (
                                          <CloseIcon
                                            fontSize="small"
                                            style={{ paddingRight: "5px" }}
                                          />
                                        )}
                                        {service.name}
                                      </div>
                                    </Column>
                                  );
                                }
                              })}
                            </>
                          );
                        })}
                      </Column>
                    );
                  })}
              </Grid>
            </Column>
          </CardSection>

          <CardSection title="Sunduğu Paketler" className="mt-5">
            <Column className="xs-12 sm-12 mb-3">
              <Alert severity="info">
                <b>{context.t(`profile.packages.alertBoldText`)}</b>
                <br />
                {context.t(`profile.packages.alertText`)}
              </Alert>
            </Column>
            <EmpoolesPackages packets={packets} updateState={updateState} />
          </CardSection>

          <CardSection
            title={context.t(`profile.booking.title`)}
            className="mt-5"
          >
            <Column className="xs-12 sm-12">
              <DayTabContainer>
                {context.t(`profile.booking.week`)?.map((item, index) => (
                  <ButtonBase key={index}>
                    <StyledDayTab
                      key={index}
                      className="ripple"
                      day={index + 1}
                      selected_day={selectedDay}
                      onClick={() => {
                        setSelectedDay(index + 1);
                      }}
                      children={item}
                    />
                  </ButtonBase>
                ))}
              </DayTabContainer>
              {loaded === true ? (
                <FormControlLabel
                  className="mb-2"
                  control={
                    <Switch
                      color="primary"
                      checked={employee.closed_appointments[selectedDay].active}
                      onChange={() => changeDayStatus(selectedDay)}
                    />
                  }
                  label={
                    <FCLabel>
                      {employee.closed_appointments[selectedDay].active === true
                        ? context.t(`profile.booking.dayCloseText`)
                        : context.t(`profile.booking.dayOpenText`)}
                    </FCLabel>
                  }
                />
              ) : (
                <div
                  style={{
                    width: 283,
                    maxWidth: "100%",
                    height: 33,
                    borderRadius: 5,
                  }}
                  className="skeleton mb-2 ml-1"
                />
              )}
            </Column>
            <Column className="xs-12 sm-12">
              {parseInt(context.state.company_shift.slice) > 0 ? (
                generatedHours.map((hour, index) => (
                  <div
                    key={index}
                    className="col xs-6 sm-2 md-2 lg-2 xl-2 mb-2 p-horizontal-1"
                  >
                    <Button
                      disabled={!employee.closed_appointments[selectedDay].active}
                      key={index}
                      fullWidth={true}
                      className={
                        employee.closed_appointments[selectedDay].active &&
                          !employee.closed_appointments[selectedDay].hours.includes(hour)
                          ? `elevation-2`
                          : ""
                      }
                      onClick={() => changeHourStatus(hour)}
                      icon={
                        employee.closed_appointments[selectedDay].active &&
                          !employee.closed_appointments[selectedDay].hours.includes(hour)
                          ? "check"
                          : "close"
                      }
                      title={hour}
                      //outlined={employee.appointments[selectedDay].active && !employee.appointments[selectedDay].hours.includes(hour)}
                      backgroundColor={"white"}
                      textColor={
                        employee.closed_appointments[selectedDay].active &&
                          !employee.closed_appointments[selectedDay].hours.includes(hour)
                          ? "green"
                          : "red"
                      }
                    />
                  </div>
                ))
              ) : (
                <Alert severity="info">
                  <b>{context.t(`profile.booking.alertBoldText`)}</b>
                </Alert>
              )}
            </Column>
          </CardSection>

          <Button
            className="mt-3 mb-1"
            icon="update"
            title={context.t(`profile.updateButtonTitle`)}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => updateSingleStaff()}
          />
        </Column>
        <ModalDialog
          open={selectedAgainServiceModal}
          closeHandler={() => {
            setSelectedAgainServiceModal(false);
          }}
          buttons={[
            {
              title: "Hepsini Ekle",
              icon: "check",

              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => {
                setEmployee({
                  ...employee,
                  service_ids: [
                    ...employee.service_ids,
                    ...selectedAgainServiceList.map((m) => m.id),
                  ],
                });
                updateSingleStaff();
              },
            },
            {
              title: "Vazgeç",
              icon: "close",
              textColor: "grey",
              onClick: () => {
                setEmployee({
                  ...employee,
                  service_ids: lastServicesIds,
                });
              },
            },
          ]}
        >
          <Alert severity="warning">
            {context.t(`employees.addAllServiceWarn`)}
          </Alert>
          <div style={{ width: "100%" }}>
            {selectedAgainServiceList.map((m) => (
              <div style={{ width: "100%" }}>-{m.name} </div>
            ))}
          </div>
        </ModalDialog>
      </Grid>
    </AuthContainer>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={
          process.env.APP_NAME === "salon"
            ? Loader
            : process.env.APP_NAME === "en"
              ? LoaderEN
              : LoaderSM
        }
        width="100"
        height="100"
        alt="loading"
      />
    </div>
  );
};

export default ProfileIndex;

const StyledDayTab = styled.div`
  width: auto;
  flex: none;
  text-align: center;
  font-size: 14px;
  padding: 16px;
  font-weight: 500 !important;
  color: ${({ day, selected_day }) =>
    day === selected_day ? "var(--primary)" : "var(--black)"};
  border-bottom: 2px solid
    ${({ day, selected_day }) =>
    day === selected_day ? "var(--primary)" : "var(--white)"};
  box-sizing: border-box;
  cursor: pointer;
`;

const DayTabContainer = styled.div`
  width: -webkit-fill-available;
  position: relative;
  z-index: 100;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden !important;
  list-style: none;
  display: inline-flex;
  margin-bottom: 16px;
  box-sizing: content-box;

  button {
    font-family: "Inter", sans-serif !important;
  }
`;

const ProfilePhoto = styled.img`
  background: #fafafa;
  width: 160px;
  height: 160px;
  max-width: 100%;
  object-fit: cover;
  border-radius: 5px;
`;

const LanguageSelective = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  padding: 10px;
`;

const Flag = styled.img`
  width: 22px;
  height: 22px;
  object-fit: cover;
  margin-right: 8px;

  & + p {
    font-size: 12px !important;
    color: #303030;
    padding: 0;
    margin: 0;
    text-transform: uppercase;
    font-weight: 500;
  }
`;
