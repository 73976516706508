import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../../context/store";
import AuthContainer from "../../../components/AuthContainer";
import styled from "styled-components";

import Button from "../../../theme/Button";

import { Grid, Column } from "../../../theme/Grid";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "../../../assets/style/views/Employees.scss";
import Axios from "axios";
import { toast } from "react-toastify";
import StaffOrderDialog from "../../../components/Dialogs/StaffOrderDialog";
import { Tooltip } from "@material-ui/core";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { FaUserEdit } from "react-icons/fa"; // FaUserEdit ikonunu import edin


const EmployeesIndex = () => {
  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "#3189ec",
          fontSize: "12px",
          backgroundColor: "white",
          boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
        },
      },
    },
  });

  const context = useContext(AppContext);
  const history = useHistory();

  const [employees, setEmployees] = useState([...Array(6).keys()]);
  const [loaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    page: null,
    total_page: 1,
  });

  const [limitResult, SetLimitResult] = useState(false);
  const [limitMessage, SetLimitMessage] = useState("");

  // const [totalEmployeeRecord, setTotalEmployeeRecord] = useState(null);
  const [allEmployees, setAllEmployees] = useState([]);
  const [open_order_staff_dialog, setOpen_order_staff_dialog] = useState(false);

  const getEmployeesLimit = async () => {
    Axios.get(`${context.api_endpoint}/company/staffs/limit/control`)
      .then((data) => {
        SetLimitResult(data.data.data.result);
        SetLimitMessage(data.data.data.message);
      })
      .catch((err) => console.log(err));
  };

  const getStaffs = async () => {
    Axios.get(
      `${context.api_endpoint}/company/staffs?page=${pagination.page ?? 1}`
    )
      .then(async ({ data }) => {
        setLoaded(true);
        setEmployees([...data.data.records]);
        setPagination({
          ...pagination,
          total_page: data.data.records.length,
          page: data.data.page,
        });
        // setTotalEmployeeRecord(data.data.records);
        getOrderedStaffs();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getAllStaffs = async () => {
    await Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        setAllEmployees([...response.data.data.map((staff) => staff)]);
      }
    );
  };

  const getOrderedStaffs = () => {
    Axios.get(`${context.api_endpoint}/company/appointments/order/list`).then(
      (response) => {
        if (response.data.data.length !== 0) {
          setAllEmployees([...response.data.data.map((staff) => staff.staff)]);
        } else {
          getAllStaffs();
        }
      }
    );
  };

  const updateStaffOrder = (orderedList) => {
    Axios.post(`${context.api_endpoint}/company/appointments/order/save`, {
      order_list: [...orderedList],
    })
      .then(async (response) => { })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  useEffect(() => {
    getStaffs();
    getEmployeesLimit();
  }, []);

  return (
    <AuthContainer authorities={[1]} limited_permission="system">
      <Grid>

        <Column className="xs-12 sm-12 md-12 lg-12 xl-12" style={{ marginBottom: "50px" }}>
          <div style={{ display: "flex", position: "relative", alignItems: "center", gap: "10px" }}>
            <span style={{ fontSize: "20px", fontWeight: "bold", position: "absolute", left: "20px" }}>{context.t(`employees.staffPageTitle`)}</span>
            <div style={{ display: "flex", gap: "10px", position: "absolute", right: "20px" }}>
              <Button
                title={context.t(`employees.orderStaffs`)}
                textColor="white"
                backgroundColor="blue"

                disabled={
                  context.state.user.permission === 2 &&
                  context.state.limited_permission.system === 1
                }
                onClick={() => {
                  setOpen_order_staff_dialog(true);
                }}
              />
              <MuiThemeProvider theme={theme}>
                <Tooltip title={<div>{limitMessage}</div>} placement="top">
                  <div>
                    <Button
                      title={context.t(`employees.createNewEmployee`)}
                      textColor="white"
                      icon="add"
                      backgroundColor="primary"
                      disabled={
                        (context.state.user.permission === 2 &&
                          context.state.limited_permission.system == 1) ||
                        !limitResult
                      }
                      onClick={() => {
                        history.push("/settings/employees/add");
                      }}
                    />
                  </div>
                </Tooltip>
              </MuiThemeProvider>
            </div>
          </div>
        </Column>
        <Column style={{ width: "100%", display: "flex", justifyContent: "center" }}>

          <GridContainer>
            {employees.map((employee, index) =>
              loaded && employee.detail ? (
                <EmployeeCard
                  key={employee.id}
                  onClick={() =>
                    history.push(`/settings/employees/detail/${employee.id}`)
                  }
                >
                  <EditButton>
                    <FaUserEdit
                      size={20}
                      color="rgb(49, 137, 239)"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/settings/employees/detail/${employee.id}`);
                      }}
                    />
                  </EditButton>
                  <ImageCircle>
                    <img
                      src={
                        employee.detail.profile_photo
                          ? `${context.api_endpoint}/${context.state.company_id}/${employee.detail.profile_photo}`.replace(
                            "api/v1/",
                            ""
                          )
                          : employee.sex === 0
                            ? require("../../../assets/images/staff_woman.jpg")
                            : employee.sex === 1
                              ? require("../../../assets/images/staff_man.jpg")
                              : require("../../../assets/images/profile_photo.svg")
                      }
                      alt=""
                    />
                  </ImageCircle>
                  <EmployeeName>{employee.full_name}</EmployeeName>
                  <JobTitle>{employee.detail.job_title}</JobTitle>
                </EmployeeCard>
              ) : (
                <EmployeeCard key={index}>
                  <SkeletonImageCircle className="skeleton" />
                  <SkeletonEmployeeName className="skeleton" />
                  <SkeletonJobTitle className="skeleton" />
                  <SkeletonActionButton className="skeleton" />
                </EmployeeCard>
              )
            )}
          </GridContainer>
        </Column>
      </Grid>
      <Grid>
        <PaginationContainer>
          <div className="pagination p7">
            <ul>
              <button
                disabled={pagination.page < 2}
                onClick={() => {
                  setPagination({
                    ...pagination,
                    page: pagination.page--,
                  });
                  getStaffs();
                }}
              >
                <li>
                  <ArrowBackIosIcon className="icon-back" fontSize="small" />
                </li>
              </button>
              <button
                disabled={employees.length < 10}
                onClick={() => {
                  setPagination({
                    ...pagination,
                    page: pagination.page++,
                  });
                  getStaffs();
                }}
              >
                <li>
                  <ArrowForwardIosIcon fontSize="small" />
                </li>
              </button>
            </ul>
          </div>
        </PaginationContainer>
      </Grid>
      <StaffOrderDialog
        open={open_order_staff_dialog}
        staffs={allEmployees}
        orderedHandler={(orderedList) => updateStaffOrder(orderedList)}
        closeHandler={() => {
          setOpen_order_staff_dialog(false);
        }}
      />
    </AuthContainer>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); // Her satırda 5 card
  gap: 20px; // Card'lar arası boşluk
  padding: 20px;
`;

const EmployeeCard = styled.div`
  background: rgba(255, 255, 255, 0.8); // Şeffaf arka plan
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative; // EditButton'u konumlandırmak için

  &:hover {
    background: rgba(49, 137, 239, 0.1); // Hover'da gri arka plan
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

`;

const ImageCircle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 15px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const EmployeeName = styled.span`
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const JobTitle = styled.span`
  display: block;
  font-size: 14px;
  color: #666;
`;

const EditButton = styled.div`
  position: absolute; // Sağ üst köşeye konumlandır
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #000; // Hover'da ikon rengini değiştir
  }
`;

const SkeletonImageCircle = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 15px;
  background-color: #e0e0e0;
`;

const SkeletonEmployeeName = styled.div`
  width: 100px;
  height: 20px;
  margin-bottom: 5px;
  background-color: #e0e0e0;
`;

const SkeletonJobTitle = styled.div`
  width: 124px;
  height: 14px;
  background-color: #e0e0e0;
  margin-bottom: 5px;
`;

const SkeletonActionButton = styled.div`
  width: 139px;
  height: 20px;
  margin-top: 26px;
  background-color: #e0e0e0;
  box-sizing: border-box;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default EmployeesIndex;
