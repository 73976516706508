import { FormControl, InputLabel, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BetweenTimeGenerator from "../functions/BetweenTimeGenerator";

const TimeSelect = (props) => {
  const HOUR = 24;
  const MINUTE = 60;

  const [allTimes, setAllTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);

  const handleChange = (event) => {
    setSelectedTime(event.target.value);
    props.handler(event.target.value);
  };

  const getType = () => {
    const type = props.type;
    if (type === "all") {
      getAllHours();
    } else if (type === "hour") {
      getHours();
    } else if (type === "minute") {
      getMinutes();
    }
  };

  const getAllHours = () => {
    const minuteStep = props.minuteStep;
    const step = 0;
    const times = [];
    for (let i = 0; i < HOUR; i++) {
      for (let j = step; j < MINUTE; j += minuteStep) {
        times.push(moment(`${i}:${j}`, "HHmm").format("HH:mm"));
      }
    }
    setAllTimes([
      ...BetweenTimeGenerator({
        start_hour: times[0],
        end_hour: times[times.length - 1],
        slice: minuteStep,
      }),
    ]);
  };

  const getHours = () => {
    const times = [];
    if (props.fullTime) {
      for (let i = 0; i < 24; i++) {
        times.push(moment(`${i}`, "HH").format("HH"));
      }
    } else {
      const companyShiftTime = props.companyShiftTime;
      const start_hour = companyShiftTime ? timeTranslator(props.startHour) : 0;
      const end_hour = companyShiftTime ? timeTranslator(props.endHour) : HOUR;

      if (parseInt(start_hour) >= parseInt(end_hour)) {
        for (let i = start_hour; i < HOUR; i++) {
          times.push(moment(`${i}`, "HH").format("HH"));
        }
        for (let i = 0; i < end_hour; i++) {
          times.push(moment(`${i}`, "HH").format("HH"));
        }
      } else {
        for (let i = start_hour; i < end_hour; i++) {
          times.push(moment(`${i}`, "HH").format("HH"));
        }
      }
    }

    if (times.length === 0) {
      console.warn('No available hours generated. Using default hours.');
      for (let i = 0; i < 24; i++) {
        times.push(moment(`${i}`, "HH").format("HH"));
      }
    }

    setAllTimes([...times]);
  };

  const getMinutes = () => {
    const times = [];

    const lastHour = props.lastHour ? props.lastHour.split(":")[0] : "";
    const minuteOfLastHour = props.lastHour ? props.lastHour.split(":")[1] : "";
    const hourOfSelectedTime = props.selectedHour
      ? props.selectedHour.split(":")[0]
      : null;

    if (
      lastHour === hourOfSelectedTime &&
      parseInt(minuteOfLastHour) < parseInt(props.selectedHour.split(":")[1])
    ) {
      props.handler(allTimes[0]);
    }

    const currentMinute =
      lastHour === hourOfSelectedTime ? parseInt(minuteOfLastHour) : MINUTE;

    if (props.step) {
      for (let i = 0; i < currentMinute; i += props.step) {
        times.push(moment(`${i}`, "mm").format("mm"));
      }
    } else {
      for (let i = 0; i < currentMinute; i++) {
        times.push(moment(`${i}`, "mm").format("mm"));
      }
    }
    setAllTimes([...times]);
  };

  const checkMinuteStep = () => {
    if (props.minuteStep > 59) {
      throw new Error("You cannot use a minute which is bigger than 59");
    }
  };

  const timeTranslator = (hour) => {
    const translatedTime = moment(`1970-01-01 ${hour}:00`);
    return translatedTime.hour();
  };

  useEffect(() => {
    getType();
    checkMinuteStep();
  }, [props.selectedHour, props.startHour, props.endHour, props.companyShiftTime, props.step, props.minuteStep, props.lastHour, props.fullTime, props.value]);

  return (
    <FormControl
      variant="outlined"
      fullWidth={props.fullWidth}
      size={props.size}
    >
      <InputLabel htmlFor="outlined-age-native-simple">
        {props.label}
      </InputLabel>
      <Select
        native
        value={props.value ?? ""}
        onChange={handleChange}
        label="Age"
      >
        {allTimes.map((time) => {
          return (
            <option value={time} key={time}>
              {time}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};

TimeSelect.propTypes = {
  type: PropTypes.oneOf(["all", "hour", "minute", "minuteRestricted"])
    .isRequired,
};

export default TimeSelect;
