import React, { useContext, useState } from "react";
import styled from "styled-components";
import AuthContainer from "../../../components/AuthContainer";
import AppContext from "../../../context/store";
import { Column, Grid } from "../../../theme/Grid";

import readXlsxFile from "read-excel-file";
import Button from "../../../theme/Button";

import Input from "../../../theme/CustomMUI/Input";
import MaskedInput from "../../../theme/CustomMUI/MaskedInput";
import Select from "../../../theme/CustomMUI/Select";
import DatePickerInput from "../../../theme/CustomMUI/DatePickerInput";

import moment from "moment";
import SectionTitle from "../../../theme/SectionTitle";
import { Check, CloudDownload } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import Axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { addWeeks } from "@fullcalendar/core";
import { select } from "redux-saga/effects";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const validify = require("validify");

const AddBulkCustomers = () => {
  const context = useContext(AppContext);

  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [customers, setCustomers] = useState({});
  const [selectedPhoneList, setSelectedPhoneList] = useState([
    {
      id: 0,
      label: context.t(`['customers/add'].firstPhoneNumber`),
      value: 1,
    },
    {
      id: 1,
      label: context.t(`['customers/add'].secondPhoneNumber`),
      value: 2,
    },
    {
      id: 2,
      label: context.t(`['customers/add'].allPhoneNumber`),
      value: 3,
    },
  ]);

  const [checkSelectNumber, setCheckSelectNumber] = useState(null);

  const SecondaryPhone_handleOnChange = (value, data, event, formattedValue) => {
    setCustomers({
      ...customers,
      secondary_phone: value,
      s_dialCode: data.dialCode,
    });
  };

  const fieldChanger = (index, payload) => {
    setCustomers({
      ...customers,
      [index]: {
        ...customers[index],
        ...payload,
      },
    });
  };

  const importBulkCustomers = async () => {
    let custom = [];
    const customersValues = Object.values(customers);
    const originalCustomers = customersValues.map((customer) => ({
      ...customer,
    }));
    const updatedCustomers = customersValues.map((customer) => {
      // Sadece secondary_phone alanını güncelleyin, diğer alanlar aynı kalsın
      customer.secondary_phone = customer.secondary_phone.slice(
        customer.s_dialCode.length
      );
      customer.name = customer.name.trim();
      customer.surname = customer.surname.trim();
      customer.dialCode = "+90";
      // customer.s_dialCode = "+" + customer.s_dialCode;
      customer.s_dialCode =
        customer.s_dialCode !== "null" ? "+" + customer.s_dialCode : null;
      customer.secondary_phone =
        customer.secondary_phone === "null" ? null : customer.secondary_phone;
      customer.selectedPhone =
        customer.selectedPhone === 1
          ? 0
          : customer.selectedPhone === 2
            ? 1
            : customer.selectedPhone === 3
              ? 2
              : 0;
      return customer;
    });
    await Axios.post(`${context.api_endpoint}/company/import/customers`, {
      customers: updatedCustomers,
    })
      .then((response) => {
        console.log(response);

        if (
          response.data &&
          response.data.data !== null &&
          response.data.data[0].hasOwnProperty("err")
        ) {
          custom = response.data.data.map((cus) => {
            return {
              phone: cus.customer.phone,
            };
          });

          if (custom.length > 0) {
            toast.warning(
              `${custom
                .map((c) => c.phone)
                .join(", ")} ${context.t(`["customers/add/bulk"].duplicatedPhoneWarnToast`)}`
            );
            setCustomers(originalCustomers);
          }
        } else {
          toast.success(response.data.data);

          history.push("/customers");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An error occurred.");
        }
      });
  };

  const uploadExcel = async (file) => {
    readXlsxFile(file)
      .then(async (rows) => {
        let customers = [];

        setLoaded(false);

        rows.map((row, index) => {
          console.log("rows123", row);
          if (
            index !== 0 &&
            row.length >= 9 &&
            validify(row[0], /.+/).valid &&
            validify(row[1], /.+/).valid &&
            validify(
              row[4]?.toString(),
              /^(5)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/
            ).valid
          )
            customers = [
              ...customers,
              {
                ___num: index,
                name: row[0],
                surname: row[1],
                nationalityID: row[2] !== null ? String(row[2]) : "",
                dialCode: String(row[3]),
                phone: row[4]?.toString().replace(/\s/g, ""),
                send_sms: row[5]
                  ? row[5].toLowerCase() === "kapalı"
                    ? false
                    : true
                  : true,
                s_dialCode: String(row[6]),
                secondary_phone: String(row[6]) + String(row[7]),
                sex: row[8] ? (row[8].toLowerCase() === "erkek" ? 1 : 0) : null,
                email: row[9],
                birthday: row[10] ? moment(row[10]).toISOString() : null,
                selectedPhone: row[11]
                  ? row[11].toLowerCase() === "birinci"
                    ? Number(1)
                    : row[11].toLowerCase() === "ikinci"
                      ? Number(2)
                      : row[11].toLowerCase() === "hepsi"
                        ? Number(3)
                        : Number(1)
                  : "birinci",
                description: row[12] !== null ? String(row[12]) : "",
              },
            ];

          return null;
        });

        setCustomers(customers);
        setLoaded(true);

        let reduceDuplicateRecords = await findDuplicateRecords();

        setCustomers(reduceDuplicateRecords.reducedCustomers);
        setLoaded(true)

        toast.success(
          context
            .t(`['customers/add/bulk'].duplicateRecords`)
            .replace("%s", reduceDuplicateRecords.duplicateCustomers.length)
        );
      })
      .catch((e) => {
        console.log(e);
        // if (e.response.status === 401) {
        //   toast.error(e.response.data.message);
        // } else {
        //   toast.warning(e.response.data.message);
        // }
      });
  };

  const findDuplicateRecords = async () => {
    const groupUniqueRecords = customers.reduce(function (eax, obj) {
      let key = obj.phone;

      if (!eax[key]) eax[key] = [];
      eax[key].push(obj);

      return eax;
    }, {});

    const compiledData = {};
    compiledData.reducedCustomers = Object.keys(groupUniqueRecords).map(
      (item) => groupUniqueRecords[item][0]
    );
    compiledData.duplicateCustomers = Object.keys(groupUniqueRecords)
      .map(
        (item) =>
          groupUniqueRecords[item].length > 1 && groupUniqueRecords[item][0]
      )
      .filter((item) => item);

    return compiledData;
  }

  return (
    <AuthContainer limited_permission="customer">
      {!loaded && (
        <Grid>
          <Column className="xs-12 sm-12">
            <input
              id="uploadInput"
              type="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: "none" }}
              onChange={(e) => uploadExcel(e.target.files?.[0])}
            />
            <UploadBox htmlFor="uploadInput">
              <i className="material-icons">cloud_upload</i>
              {context.t(`['customers/add/bulk'].uploadAreaText`)}
            </UploadBox>

            <div severity="info" className="mt-4">
              <h3>{context.t(`['customers/add/bulk'].stepsHeading`)}</h3>
              <AlertList>
                <li>{context.t(`['customers/add/bulk'].steps[0]`)}</li>
                <li>{context.t(`['customers/add/bulk'].steps[1]`)}</li>
                <li>
                  <Button
                    iconComponent={CloudDownload}
                    title={context.t(`['customers/add/bulk'].steps[2]`)}
                    backgroundColor="green-opacity"
                    textColor="green"
                    size="xs"
                    onClick={() =>
                      window.open(
                        "https://app.salonrandevu.com/excel/Salonrandevu_MusteriExcel.xlsx"
                      )
                    }
                  />
                </li>
                <li>{context.t(`['customers/add/bulk'].steps[3]`)}</li>
              </AlertList>
              <p className="mt-2" style={{ fontWeight: 600 }}>
                {context.t(`['customers/add/bulk'].boldText`)}
              </p>
            </div>
          </Column>
        </Grid>
      )}
      {loaded && (
        <>
          <Grid className="mb-2">
            <Column className="xs-auto sm-auto">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SectionTitle style={{ fontSize: 19 }}>
                  {context.t(`['customers/add/bulk'].preview.heading`)}
                </SectionTitle>
              </div>
            </Column>
            <Column className="xs-12 sm-12 md-4 lg-4">
              <Button
                iconComponent={Check}
                title={context.t(
                  `['customers/add/bulk'].preview.completeButtonTitle`
                )}
                backgroundColor="primary"
                textColor="white"
                size="md"
                fullWidth
                onClick={() => importBulkCustomers()}
              />
            </Column>
          </Grid>

          <Grid>
            <Column className="xs-12 sm-12">
              {[...Object.keys(customers)]
                .splice(page * 10, 10)
                .map((key, index) => (
                  <Grid key={key} className={`no-gutters-row mb-3`}>
                    <CustomerRowIndex>
                      {page * 10 + index + 1}
                    </CustomerRowIndex>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Input
                        required
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.nameInputLabel`
                        )}
                        defaultValue={customers[key].name ?? ""}
                        onChange={(e) =>
                          e.target.value &&
                          fieldChanger(key, { name: e.target.value })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Input
                        required
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.surnameInputLabel`
                        )}
                        defaultValue={customers[key].surname ?? ""}
                        onChange={(e) =>
                          e.target.value &&
                          fieldChanger(key, { surname: e.target.value })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Input
                        required
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.nationalID`
                        )}
                        defaultValue={customers[key].nationalityID ?? ""}
                        onChange={(e) => {
                          e.target.value &&
                            fieldChanger(key, {
                              nationalityID: e.target.value.trim(),
                            });
                        }}
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <MaskedInput
                        style={{ border: "1px solid black" }}
                        required
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.phoneInputLabel`
                        )}
                        maskChar={""}
                        mask="999 999 9999"
                        startAdornment="+90"
                        value={customers[key].phone ?? ""}
                        placeholder="500 000 0000"
                        onChange={(e) =>
                          e.target.value &&
                          fieldChanger(key, {
                            phone:
                              e.target.value.substring(0, 1) === "5" &&
                              e.target.value.replace(/\s/g, ""),
                          })
                        }
                      />
                      <PhoneInput
                        className="input-phone-number"
                        inputStyle={{ border: "none", width: "100%", height: "70%", boxShadow: "0px 0px 4px 0px #f9f9f9", borderRadius: "4px" }}
                        containerStyle={{ marginBottom: "13px" }}
                        style={{ marginTop: "20px" }}
                        country={customers[key].s_dialCode}
                        countryCodeEditable={false}
                        value={String(customers[key].secondary_phone)}
                        placeholder={"Enter a phone number"}
                        onChange={(value, data, event, formattedValue) => {
                          // console.log(value, data.dialCode);
                          console.log(event.target.value);
                          fieldChanger(key, {
                            secondary_phone: String(value),
                            s_dialCode: String(data.dialCode),
                          });
                        }}
                        specialLabel={context.t(
                          `['customers/add'].secondPhoneInputLabel`
                        )}
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Select
                        required
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.sendSmsInputLabel`
                        )}
                        selected={
                          customers[key].send_sms !== null &&
                            customers[key].send_sms !== undefined
                            ? `${customers[key].send_sms}`
                            : ""
                        }
                        items={[
                          {
                            label: context.t(
                              `['customers/add/bulk'].preview.sendSmsTrue`
                            ),
                            value: true,
                          },
                          {
                            label: context.t(
                              `['customers/add/bulk'].preview.sendSmsFalse`
                            ),
                            value: false,
                          },
                        ]}
                        labelKey="label"
                        valueKey="value"
                        handler={(send_sms) =>
                          fieldChanger(key, {
                            send_sms: send_sms === "true",
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Select
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.genderInputLabel`
                        )}
                        selected={
                          customers[key].sex !== null &&
                            customers[key].send_sms !== undefined
                            ? `${customers[key].sex}`
                            : ""
                        }
                        items={[
                          {
                            label: context.t(
                              `['customers/add/bulk'].preview.genderFemale`
                            ),
                            value: "0",
                          },
                          {
                            label: context.t(
                              `['customers/add/bulk'].preview.genderMale`
                            ),
                            value: "1",
                          },
                        ]}
                        labelKey="label"
                        valueKey="value"
                        handler={(sex) =>
                          fieldChanger(key, { sex: parseInt(sex) })
                        }
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Input
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.mailInputLabel`
                        )}
                        value={
                          customers[key].email !== null
                            ? customers[key].email
                            : ""
                        }
                        onChange={(e) =>
                          e.target.value &&
                          fieldChanger(key, { email: e.target.value })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <DatePickerInput
                        className="mb-0"
                        label={context.t(
                          `['customers/add/bulk'].preview.birthdayInputLabel`
                        )}
                        error={false}
                        minDate={new Date("01-01-1900")}
                        value={
                          customers[key].birthday !== null &&
                            customers[key].birthday !== undefined
                            ? moment(customers[key].birthday)
                            : null
                        }
                        onChange={(date) =>
                          fieldChanger(key, {
                            birthday: moment(date).toISOString(),
                          })
                        }
                      />
                    </Column>
                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Select
                        required
                        className="mb-0"
                        label={context.t(`["customers/add/bulk"].preview.selectedPhoneLabel`)}
                        selected={
                          customers[key].selectedPhone &&
                          Number(customers[key].selectedPhone)
                        }
                        items={selectedPhoneList}
                        labelKey="label"
                        valueKey="value"
                        handler={(e) => {
                          fieldChanger(key, {
                            selectedPhone: Number(e),
                          });
                        }}
                      />
                    </Column>

                    <Column className="xs-12 sm-12 md-12 lg-12 xl-auto">
                      <Input
                        className="mb-0"
                        label={context.t(`["customers/add/bulk"].preview.descriptionLabel`)}
                        value={
                          customers[key].description !== null
                            ? customers[key].description
                            : ""
                        }
                        onChange={(e) =>
                          fieldChanger(key, {
                            description: e.target.value,
                          })
                        }
                      />
                    </Column>
                  </Grid>
                ))}
              <PaginationContainer>
                <Pagination
                  count={
                    [...Object.keys(customers)].length % 10 === 0
                      ? [...Object.keys(customers)].length / 10
                      : parseInt([...Object.keys(customers)].length / 10) + 1
                  }
                  onChange={(event, value) =>
                    setPage(value - 1)
                  }
                />
              </PaginationContainer>
            </Column>
          </Grid>
        </>
      )}
    </AuthContainer>
  );
}

export default AddBulkCustomers;

const UploadBox = styled.label`
    display: flex;
    width: -webkit-fill-available;
    padding: 32px;
    text-align: center;
    align-items: center;
    justify-content: center;
    background: #FEFEFE;
    border: 2px dotted #EAEAEA;
    border-radius: 5px;
    cursor: pointer;
    color: #808080;
    font-size: 15px;
    font-weight: 500;
    transition: all ease 0.35s;

    &:hover{
                border: 2px dotted #DADADA;
        color: #000
    }

    i.material-icons {
                font - size: 21px;
    margin-right: 8px
    }
`;

const CustomerRowIndex = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #aaa;

  width: 48px;
  height: 59.33px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlertList = styled.ol`
  margin-bottom: 0;
  padding-inline-start: 12px;

  li {
    padding-left: 8px;
    margin-bottom: 16px;
    font-weight: 500;

    &:nth-last-child(1) {
      margin: 0;
    }
  }
`;

