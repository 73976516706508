import React, { useState, useContext, useEffect, useRef } from "react";
import AppContext from "../../../../context/store";
import AuthContainer from "../../../../components/AuthContainer";
import Axios from "axios";
import CalculateMessageCount from "../../../../functions/CalculateMessageCount";
import {
  Block,
  FeaturedPlayList,
  FilterList,
  WhatsApp,
} from "@material-ui/icons";

import { Grid, Column } from "../../../../theme/Grid";
import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import {
  Switch,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  FormGroup,
  Slider,
  Tooltip,
  Chip,
} from "@material-ui/core";
import FCLabel from "../../../../theme/FCLabel";
import getFilterModel from "./_/FilterModel";
import Table from "../../../../theme/Table";
import Button from "../../../../theme/Button";
import SectionTitle from "../../../../theme/SectionTitle";
import CalculateMessageCharCount from "../../../../functions/CalculateMessageCharCount";
import Input from "../../../../theme/CustomMUI/Input";
import AlertDialog from "../../../../theme/CustomMUI/AlertDialog";
// import GenerateMessagePreview from '../../../../functions/GenerateMessagePreview';
import { toast } from "react-toastify";
import UndefinedSenderAlert from "../../../../components/Alerts/UndefinedSenderAlert";
import WpUndefinedAlert from "../../../../components/Alerts/WpUndefinedAlert";
import SenderFetchAlert from "../../../../components/Alerts/SenderFetchAlert";
import Select from "../../../../theme/CustomMUI/Select";
import StatsCard from "../../../../components/Cards/StatsCard";
import SMSReview from "../../../../components/Dialogs/SMSReview";
import { phoneNumberDisplay } from "../../../../functions/PhoneNumberDisplay";
import BetweenTimeGenerator from "../../../../functions/BetweenTimeGenerator";
import moment from "moment";
import DatePickerInput from "../../../../theme/CustomMUI/DatePickerInput";
import TimeSelect from "../../../../theme/TimeSelect";
import { useHistory } from "react-router-dom";
import useHandleParams from "../../../../functions/HandleParams";

const SendFilter = () => {
  const context = useContext(AppContext);
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [services, setServices] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [categories, setCategories] = useState([]);
  const HandeParams = useHandleParams()
  const [customers_table, setCustomersTable] = useState({
    loaded: false,
    data: [],
    pagination: {
      page: 1,
      total_page: 1,
      onChange: (page) => setCustomersTable((prev) => ({ ...prev, pagination: { ...prev.pagination, page: page } })),
    },
  });

  const [check_dialog, setCheckDialog] = useState(false);

  const [filter, setFilter] = useState(getFilterModel());
  const [message, setMessage] = useState("");
  const [except, setExcept] = useState([]);
  const [send_date, setSendDate] = useState(new Date());
  const [selected_time, setSelectedTime] = useState(null);
  const [hours, setHours] = useState([
    ...BetweenTimeGenerator(context.state.company_shift),
  ]);
  const [future_date, setFutureDate] = useState(false);

  const [templates, setTemplates] = useState([]);
  const [selected_template, setSelectedTemplate] = useState(null);

  const [open_sms_review_dialog, setOpenSmsReviewDialog] = useState(false);
  const [total_number, setTotalNumber] = useState(null);
  const [total_sms_credit, setTotalSmsCredit] = useState(null);
  const [shortCode, setShortCode] = useState(null);

  const [sender, setSender] = useState("");

  // Checkbox for sms and wp
  const [checkedSMS, setCheckedSMS] = useState(true);
  const [checkedWP, setCheckedWP] = useState(true);

  // whatsapp or sms
  const [api_key, setApiKey] = useState("");

  // WPState and WP Phone
  const [wp_state, setWPState] = useState(null);
  const [wp_phone, setWPPhone] = useState(null);
  const [sms_request_status, setSmsRequestStatus] = useState(false);


  useEffect(() => {
    getFilteredCustomers()
  }, [customers_table.pagination.page])

  // checking dor wp accounts
  const checkWp = async () => {
    await Axios.get(`${context.api_endpoint}/company/get/wp/message/settings`)
      .then(async (ress) => {
        if (ress.data.data.device_key !== "") {
          await Axios.post(
            `${context.api_endpoint}/salon/wp/login/check/active/${ress.data.data.device_key}`
          )
            .then((ress) => {
              if (ress.status === 200) {
                setWPState(ress.data.is_connect);
                setWPState(ress.data.data.device_number);
                setCheckedWP(true);
              }
            })
            .catch(() => {
              setWPState(false);
              setWPState(null);
              setCheckedWP(false);
            });
        } else {
          setCheckedWP(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const loadData = () => {
    Axios.get(`${context.api_endpoint}/company/appointments/index2`).then(
      ({ data }) => {
        setStaffs([...data.data]);
      }
    );
  };

  const filterFormatter = () => {
    const _filter = { ...getFilterModel() };

    let formatted = {};
    Object.keys(_filter).map((key) => {
      if (filter[key] === null || filter[key] === undefined) {
        return;
      }

      return Object.assign(formatted, {
        [key]:
          JSON.stringify(_filter[key]) === JSON.stringify(filter[key])
            ? getFilterModel({ type: "FULFILLED", services, staffs })[key]
            : filter[key],
      });
    });

    return formatted;
  };

  const getAllCategories = async () => {
    await Axios.get(
      `${context.api_endpoint}/company/services/with/category/all`
    ).then(({ data }) => {
      setCategories([...data.data.filter((m) => m.services.length !== 0)]);
    });
  };

  const getFilteredCustomers = () => {
    setCustomersTable({ ...customers_table, loaded: false });
    Axios.post(`${context.api_endpoint}/company/filter/sms?page=${customers_table.pagination.page}`, {
      ...filterFormatter(),
    })
      .then(({ data }) => {
        setCustomersTable({
          ...customers_table,
          loaded: true,
          pagination: {
            ...customers_table.pagination,
            page: data.data.page,
            total_page: data.data.records.length,
          },
          data: [
            ...data.data.records.map((item) => ({
              ...item,
              full_name: `${item.name} ${item.surname}`,
              phone:
                context.state.user.permission === 2 &&
                  context.state.limited_permission.customer_info === 0
                  ? phoneNumberDisplay(item.phone)
                  : item.phone,
            })),
          ],
        });
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      });
  };

  const getBalance = async () => {
    Axios.get(`${context.api_endpoint}/company/balance`)
      .then((data) => {
        // setWpBalance(data.data.data.wp_credit);
        context.dispatch({
          type: "SET_BALANCE",
          payload: { balance: data.data },
        });
      })
      .catch((err) => console.log(err));
  };

  const sendFilteredSms = async () => {
    setSmsRequestStatus(true);

    let appointment_date = null;
    if (future_date === true) {
      let appointment_time = selected_time.split(":");
      appointment_date = `${moment(send_date).format("YYYY-MM-DD")}T${appointment_time[0]
        }:${appointment_time[1]}:00Z`;
    }

    let sms_prefer = (() => {
      if (checkedSMS && checkedWP) {
        return 3;
      }
      if (checkedSMS) {
        return 1;
      } else {
        if (checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0 && process.env.APP_NAME !== "management") {
      return toast.error(context.t(`["sms/filter"].sendFilteredSmsErrorToast`));
    }

    await Axios.post(`${context.api_endpoint}/company/filter/sms/send`, {
      // Tüm filtreler
      ...filterFormatter(),

      //Hariç tutulacak müşteriler listesi
      except: except.map((item) => item.id),

      //Gönderilecek mesaj içeriği
      message,

      send_date: future_date === true ? appointment_date : null,

      // hangi yöntem ile göndereceğini
      sms_prefer: process.env.APP_NAME === "management" ? 2 : sms_prefer,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success(context.t(`["sms/filter"].sendFilteredSmsSuccessToast`));
          history.push("/sms");
          getBalance();
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });

    setSmsRequestStatus(false);
  };

  const getTemplates = () => {
    Axios.get(`${context.api_endpoint}/company/sms/template/all`).then(
      ({ data }) => {
        setTemplates([...data.data]);
      }
    );
  };

  const getMessageSettings = () => {
    Axios.get(`${context.api_endpoint}/company/message/credit`).then(
      ({ data }) => {
        setSender(data.data.settings.sender);
        if (
          data.data.settings.sender === "" ||
          data.data.settings.sender === null
        ) {
          setCheckedSMS(false);
        }
      }
    );
  };

  const getSMSReview = () => {
    let sms_prefer = (() => {
      if (checkedSMS && checkedWP) {
        return 3;
      }
      if (checkedSMS) {
        return 1;
      } else {
        if (checkedWP) {
          return 2;
        } else {
          return 0;
        }
      }
    })();
    if (sms_prefer === 0 && process.env.APP_NAME !== "management") {
      return toast.error(context.t(`["sms/filter"].sendFilteredSmsErrorToast`));
    }
    Axios.post(`${context.api_endpoint}/company/filter/sms/send/preview`, {
      ...filterFormatter(),
      except: except.map((item) => item.id),
      message: message,
      sms_prefer: process.env.APP_NAME === "management" ? 2 : sms_prefer,
    })
      .then((response) => {
        if (response.status === 200) {
          setTotalNumber(response.data.data.total_numbers);
          setTotalSmsCredit(response.data.data.total_sms_credit);
          setOpenSmsReviewDialog(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          toast.error(e.response.data.message);
        } else {
          toast.warning(e.response.data.message);
        }
      });
  };

  const getShortcode = () => {
    Axios.get(`${context.api_endpoint}/company/shortcode/list/keyword`)
      .then((response) => {
        if (response.status === 200) {
          setShortCode(response.data.data.keyword);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  /**
   * Filtreleme alanının yapıldığı sol menünün olduğu alan.
   */
  const FilterAreaBuilder1 = () => {
    return (
      <Column
        className="xs-12 sm-12 md-12 lg-4 xl-3"
        style={{ display: step === 0 ? "block" : "none" }}
      >
        {process.env.APP_NAME === "en" ? null : (
          <>
            {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
              <FilterTab
                title={context.t(`['sms/filter'].tabs.debt`)}
                typeKey="debt"
              >
                <FilterCheckboxGroup
                  name="debt"
                  checkboxes={[
                    {
                      label: context.t(`['sms/filter'].tabs.debtFalse`),
                      value: 0,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.debtTrue`),
                      value: 1,
                    },
                  ]}
                />
              </FilterTab>
            )) ||
              null}

            {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
              <FilterTab
                title={context.t(`['sms/filter'].tabs.risk`)}
                typeKey="risk_list"
              >
                <FilterRadioGroup
                  radios={[
                    {
                      label: context.t(`['sms/filter'].tabs.risk15`),
                      value: 15,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.risk30`),
                      value: 30,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.risk45`),
                      value: 45,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.risk60`),
                      value: 60,
                    },
                  ]}
                  value={filter.risk_list}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      risk_list: parseInt(e.target.value),
                    })
                  }
                />
              </FilterTab>
            )) ||
              null}

            {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
              <FilterTab
                title={context.t(`['sms/filter'].tabs.service`)}
                typeKey="services"
              >
                <FilterCheckboxGroup
                  name="services"
                  checkboxes={[
                    ...categories.map((service) => ({
                      label: service.name,
                      value: service.id,
                    })),
                  ]}
                />
              </FilterTab>
            )) ||
              null}
          </>
        )}
        <FilterTab
          title={context.t(`['sms/filter'].tabs.staff`)}
          typeKey="staffs"
        >
          <FilterCheckboxGroup
            name="staffs"
            checkboxes={[
              ...staffs.map((staff) => ({
                label: staff.full_name,
                value: staff.id,
              })),
            ]}
          />
        </FilterTab>

        {process.env.APP_NAME === "management" ? (
          <>
            <FilterTab
              title={context.t(`['sms/filter'].tabs.work`)}
              typeKey="working_status"
            >
              <FilterCheckboxGroup
                name="working_status"
                checkboxes={[
                  {
                    value: 2,
                    label: context.t(`['sms/filter'].tabs.workWorking`),
                  },
                  {
                    value: 1,
                    label: context.t(`['sms/filter'].tabs.workUnemployed`),
                  },
                  {
                    value: 3,
                    label: context.t(`['sms/filter'].tabs.workEmployer`),
                  },
                  {
                    value: 0,
                    label: context.t(`['sms/filter'].tabs.workStudent`),
                  },
                  {
                    value: 4,
                    label: context.t(`['sms/filter'].tabs.workHouseWife`),
                  },
                ]}
              />
            </FilterTab>

            <FilterTab
              title={context.t(`['sms/filter'].tabs.marital`)}
              typeKey="marital_status"
            >
              <FilterCheckboxGroup
                name="marital_status"
                checkboxes={[
                  {
                    value: 1,
                    label: context.t(`['sms/filter'].tabs.maritalMarried`),
                  },
                  {
                    value: 0,
                    label: context.t(`['sms/filter'].tabs.maritalSingle`),
                  },
                ]}
              />
            </FilterTab>
          </>
        ) : null}

        <FilterTab
          title={context.t(`['sms/filter'].tabs.gender`)}
          typeKey="gender"
        >
          <FilterCheckboxGroup
            name="gender"
            checkboxes={[
              {
                value: 1,
                label: context.t(`['sms/filter'].tabs.genderMale`),
              },
              {
                value: 0,
                label: context.t(`['sms/filter'].tabs.genderFemale`),
              },
            ]}
          />
        </FilterTab>

        <FilterTab
          title={context.t(`['sms/filter'].tabs.age`)}
          typeKey="age_range"
        >
          <div className="mb-3 pl-1 pr-1">
            <Slider
              defaultValue={[1, 75]}
              onChangeCommitted={(_, value) =>
                setFilter({ ...filter, age_range: value })
              }
              valueLabelDisplay="on"
              ValueLabelComponent={({ value, children }) => (
                <Tooltip
                  style={{ background: "#888" }}
                  open
                  placement="bottom"
                  title={value}
                >
                  {children}
                </Tooltip>
              )}
              min={1}
              step={1}
              max={75}
            />
          </div>
        </FilterTab>

        <Button
          iconComponent={FilterList}
          title={context.t(`['sms/filter'].applyFilterButtonTitle`)}
          textColor="primary"
          backgroundColor="primary-opacity"
          fullWidth
          onClick={() => getFilteredCustomers()}
        />

        {/*<pre style={{ fontSize: 12, whiteSpace: 'pre-wrap' }}>{JSON.stringify(filterFormatter(), 0, 1)}</pre>*/}
      </Column>
    );
  };

  const FilterAreaBuilder = () => {
    return (
      <Column
        className="xs-12 sm-12 md-12 lg-4 xl-3"
        style={{ display: step === 0 ? "block" : "none" }}
      >
        {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
          <>
            {process.env.APP_NAME !== "en" && (
              <FilterTab
                title={context.t(`['sms/filter'].tabs.debt`)}
                typeKey="debt"
                openDefault={false}
              >
                <FilterCheckboxGroup
                  name="debt"
                  checkboxes={[
                    {
                      label: context.t(`['sms/filter'].tabs.debtFalse`),
                      value: 0,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.debtTrue`),
                      value: 1,
                    },
                  ]}
                />
              </FilterTab>
            )}
          </>
        )) ||
          null}

        {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
          <>
            {process.env.APP_NAME !== "en" && (
              <FilterTab
                title={context.t(`['sms/filter'].tabs.risk`)}
                typeKey="risk_list"
              >
                <FilterRadioGroup
                  radios={[
                    {
                      label: context.t(`['sms/filter'].tabs.risk15`),
                      value: 15,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.risk30`),
                      value: 30,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.risk45`),
                      value: 45,
                    },
                    {
                      label: context.t(`['sms/filter'].tabs.risk60`),
                      value: 60,
                    },
                  ]}
                  value={filter.risk_list}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      risk_list: parseInt(e.target.value),
                    })
                  }
                />
              </FilterTab>
            )}
          </>
        )) ||
          null}

        {(context.current_flag & context.state.FLAGS.SALON_RANDEVU && (
          <>
            {process.env.APP_NAME !== "en" && (
              <FilterTab
                title={context.t(`['sms/filter'].tabs.service`)}
                typeKey="services"
              >
                <FilterCheckboxGroup
                  name="services"
                  checkboxes={[
                    ...categories.map((service) => ({
                      label: service.name,
                      value: service.id,
                    })),
                  ]}
                />
              </FilterTab>
            )}
          </>
        )) ||
          null}

        <FilterTab
          title={context.t(`['sms/filter'].tabs.staff`)}
          typeKey="staffs"
        >
          <FilterCheckboxGroup
            name="staffs"
            checkboxes={[
              ...staffs.map((staff) => ({
                label: staff.full_name,
                value: staff.id,
              })),
            ]}
          />
        </FilterTab>

        <FilterTab
          title={context.t(`['sms/filter'].tabs.gender`)}
          typeKey="gender"
        >
          <FilterCheckboxGroup
            name="gender"
            checkboxes={[
              {
                value: 1,
                label: context.t(`['sms/filter'].tabs.genderMale`),
              },
              {
                value: 0,
                label: context.t(`['sms/filter'].tabs.genderFemale`),
              },
            ]}
          />
        </FilterTab>

        <FilterTab
          title={context.t(`['sms/filter'].tabs.age`)}
          typeKey="age_range"
          onOpen={() => {
            setFilter((prev) => ({
              ...prev,
              age_range: [1, 75],
            }));
          }}
          onClose={() => {
            setFilter((prev) => ({
              ...prev,
              age_range: null,
            }));
          }}
        >
          <div className="mb-3 pl-1 pr-1">
            <Slider
              defaultValue={filter.age_range ?? [1, 75]}
              onChangeCommitted={async (_, value) => {
                console.log(value);
                setFilter((prev) => ({
                  ...prev,
                  age_range: value,
                }));
              }}
              valueLabelDisplay="on"
              ValueLabelComponent={({ value, children }) => (
                <Tooltip
                  style={{ background: "#888" }}
                  open
                  placement="bottom"
                  title={value}
                >
                  {children}
                </Tooltip>
              )}
              min={1}
              step={1}
              max={75}
            />
          </div>
        </FilterTab>

        <Button
          iconComponent={FilterList}
          title={context.t(`['sms/filter'].applyFilterButtonTitle`)}
          textColor="primary"
          backgroundColor="primary-opacity"
          fullWidth
          onClick={() => {
            if (customers_table.pagination.page === 1) {
              getFilteredCustomers()
            } else {
              HandeParams('page', 1)
              setCustomersTable((prev) => ({ ...prev, pagination: { ...prev.pagination, page: 1 } }))
            }
          }}
        />
        {/*<pre style={{ fontSize: 12, whiteSpace: 'pre-wrap' }}>{JSON.stringify(filterFormatter(), 0, 1)}</pre>*/}
      </Column>
    );
  };

  /**
   * Filtreleme işlemleri sonrasında anlık güncellenen müşteriler tablosu.
   */
  const CustomersBuilder = () => {
    return (
      <Column
        className="xs-12 sm-12 md-12 lg-5 xl-5"
        style={{ display: step === 0 ? "block" : "none" }}
      >
        <SectionTitle>
          {context.t(`['sms/filter'].customer.title`)}
        </SectionTitle>
        <Table
          refreshAction={() =>
            getFilteredCustomers(customers_table.pagination.page)
          }
          loaded={customers_table.loaded}
          headings={{
            //"id": { label: "Müşteri Kodu" },
            full_name: {
              label: context.t(`['sms/filter'].customer.headings.fullName`),
            },
            phone: {
              label: context.t(`['sms/filter'].customer.headings.phone`),
              style: { width: 150 },
            },
            sex: {
              label: context.t(`['sms/filter'].customer.headings.gender`),
            },
            _: { label: context.t(`component.actionHeadingText`) },
          }}
          replacements={{
            sex: {
              null: context.t(
                `['sms/filter'].customer.replacements.genderNull`
              ),
              0: context.t(`['sms/filter'].customer.replacements.genderFemale`),
              1: context.t(`['sms/filter'].customer.replacements.genderMale`),
              3: context.t(`customers.replacements.sex.undef`),
            },
          }}
          rows={customers_table.data}
          pagination={customers_table.pagination}
          buttons={[
            {
              iconComponent: Block,
              textColor: "red",
              disabled: (row) =>
                except.filter((_row) => _row.id === row.id).length > 0,
              transitionEffect: true,
              onClick: (row) =>
                setExcept(
                  except.includes(row)
                    ? [...except.filter((_row) => _row !== row)]
                    : [...except, row]
                ),
            },
          ]}
        />
      </Column>
    );
  };

  /**
   * Gönderimden hariç tutulacak müşteri kolonu.
   */
  const ExceptedCustomersBuilder = () => {
    return (
      <Column className="xs-12 sm-12 md-12 lg-auto xl-auto">
        <SectionTitle className="mb-1">
          {context.t(`['sms/filter'].except.title`)}
        </SectionTitle>
        <ChipContainer>
          {except.length > 0 ? (
            except.map((item) => (
              <Chip
                style={{ height: 46, borderRadius: 5 }}
                className="mr-2 mb-2 bg-red-opacity fc-red fw-500"
                label={item.full_name}
                onDelete={() =>
                  setExcept([...except.filter((_item) => _item !== item)])
                }
              />
            ))
          ) : (
            <Alert
              severity="info"
              style={{ width: "100%", boxSizing: "border-box" }}
            >
              <b>{context.t(`['sms/filter'].except.alertBoldText`)}</b>
              <br />
              {context.t(`['sms/filter'].except.alertText`)}
            </Alert>
          )}
        </ChipContainer>

        {step === 0 && (
          <Button
            className="mt-3"
            title={context.t(`['sms/filter'].continueButtonTitle`)}
            icon="chevron_right"
            backgroundColor="primary"
            textColor="white"
            fullWidth
            onClick={() => setStep(1)}
          />
        )}
      </Column>
    );
  };

  /**
   * SMS gönderim alanıdır.
   */
  const SmsSettingsBuilder = () => {
    const messageRef = useRef(null);
    React.useEffect(() => {
      if (messageRef.current) {
        messageRef.current.focus();
        messageRef.current.setSelectionRange(message.length, message.length);
      }
    }, [message]);
    return (
      <>
        <Column
          className="xs-12 sm-12 md-12 lg-8"
          style={{ display: step === 1 ? "block" : "none" }}
        >
          <Column className="xs-12 sm-12 md-12 lg-8">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={future_date}
                  onChange={(e) => {
                    setFutureDate(e.target.checked);
                  }}
                />
              }
              label={
                <FCLabel>
                  <i className="material-icons">filter_list</i>{" "}
                  <span>{context.t(`sms.send.futureDate`)}</span>
                </FCLabel>
              }
            />
          </Column>
          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
              <Select
                label={context.t(`sms.send.template`)}
                items={[...templates]}
                selected={selected_template?.name ?? ""}
                labelKey="name"
                valueKey="name"
                returnFullObject
                handler={(template) => {
                  setSelectedTemplate(template);
                  setMessage(template.content);
                }}
              />
            </Column>
            {future_date && (
              <>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <DatePickerInput
                    label={context.t(`sms.send.sendDate`)}
                    value={new Date(send_date).toISOString()}
                    onChange={(date) => {
                      setSendDate(new Date(date).toISOString());
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    label={context.t(`['settings/hours'].startInputLabelHour`)}
                    type="hour"
                    value={
                      selected_time !== null ? selected_time.split(":")[0] : ""
                    }
                    fullWidth={true}
                    size="medium"
                    companyShiftTime={true}
                    startHour={1}
                    endHour={23}
                    // minuteStep={5}
                    handler={(value) => {
                      setSelectedTime(
                        selected_time !== null
                          ? `${value}:${selected_time.split(":")[1]}`
                          : `${value}:00`
                      );
                    }}
                  />
                </Column>
                <Column className="xs-12 sm-12 md-12 lg-12 xl-3">
                  <TimeSelect
                    label={context.t(
                      `['settings/hours'].startInputLabelMinute`
                    )}
                    type="minute"
                    value={
                      selected_time !== null ? selected_time.split(":")[1] : ""
                    }
                    selectedHour={selected_time}
                    lastHour={hours[hours.length - 1]}
                    fullWidth={true}
                    size="medium"
                    handler={(time) => {
                      setSelectedTime(
                        selected_time !== null
                          ? `${selected_time.split(":")[0]}:${time}`
                          : null
                      );
                    }}
                  />
                </Column>
              </>
            )}
          </Grid>
          <Input
            label={context.t(`sms.send.messageInputLabel`)}
            inputRef={messageRef}
            autoFocus
            multiline={true}
            rows={10}
            value={message ?? ""}
            onChange={e => setMessage(e.target.value)}
            helperText={CalculateMessageCharCount(
              message,
              `${checkedSMS && checkedWP
                ? "1"
                : !checkedSMS && checkedWP
                  ? "3"
                  : "1"
              } `
            )}
          />

          <Grid>
            <Column className="xs-12 sm-12 md-12 lg-6">
              <Button
                className="mb-1"
                title={context.t(`sms.send.autoText.nameSurname`)}
                size="xs"
                textColor="primary"
                outlined
                onClick={() => setMessage(`${message}|ad soyad|`)}
              />
              <Button
                className="mb-1 ml-1"
                title={context.t(`sms.send.autoText.shortCode`)}
                size="xs"
                textColor="purple"
                outlined
                disabled={shortCode === ""}
                onClick={() =>
                  setMessage(
                    context.t(`["sms/filter"].shortCodeButtonMessage`).replace("%s", message).replace("%v", shortCode)
                  )
                }
              />
            </Column>
          </Grid>

          <Button
            disabled={
              message.length === 0 ||
              !CalculateMessageCount(
                message,
                `${checkedSMS && checkedWP
                  ? "1"
                  : !checkedSMS && checkedWP
                    ? "3"
                    : "1"
                } `
              )
            }
            icon="send"
            title={context.t("menuItems.sms.children.sendFilter")}
            backgroundColor="primary"
            textColor="white"
            fullWidth={true}
            onClick={() => getSMSReview()}
          />

          <Button
            className="mt-1"
            icon="chevron_left"
            title={context.t(`['sms/filter'].backToFilterButtonTitle`)}
            fullWidth
            onClick={() => setStep(0)}
          />
        </Column>

        <AlertDialog
          title={context.t(`sms.send.check.title`)}
          open={check_dialog}
          textType={true}
          closeHandler={() => setCheckDialog(false)}
          buttons={[
            {
              icon: "check",
              title: context.t(`sms.send.check.confirmButtonTitle`),
              backgroundColor: "primary-opacity",
              textColor: "primary",
              onClick: () => getSMSReview(),
            },
            {
              icon: "close",
              title: context.t(`sms.send.check.discardButtonTitle`),
              textColor: "grey",
            },
          ]}
        >
          {/*
          <MessagePreview className="mb-3">
            <h5>Gönderilecek Örnek Mesaj:</h5>
            <p>
              GenerateMessagePreview(message ?? '', {
                '|ad soyad|': customers_table.data.filter(customer => !except.includes(customer))?.[0]?.full_name
              })
            </p>
          </MessagePreview>*/}

          <Alert severity="warning">
            {context.t(`sms.send.check.alertText`)}
          </Alert>
        </AlertDialog>
      </>
    );
  };

  const FilterCheckboxGroup = ({ checkboxes, name }) => {
    return (
      <FormGroup>
        {checkboxes?.map((item, index) => (
          <FormControlLabel
            style={{
              width: "100%",
              margin: checkboxes.length - 1 === index ? 0 : "0 0 8px",
            }}
            label={<FCLabel>{item.label}</FCLabel>}
            control={
              <Checkbox
                style={{ padding: 0, marginRight: 4 }}
                checked={filter[name].includes(item.value)}
                onClick={() => {
                  filter[name].includes(item.value)
                    ? setFilter({
                      ...filter,
                      [name]: filter[name].filter((i) => i !== item.value),
                    })
                    : setFilter({
                      ...filter,
                      [name]: [...filter[name], item.value],
                    });
                }}
                color="primary"
              />
            }
          />
        ))}
      </FormGroup>
    );
  };

  const FilterRadioGroup = ({ name, value, onChange, radios }) => {
    return (
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
      >
        {radios?.map((item, index) => (
          <FormControlLabel
            style={{
              width: "100%",
              margin: radios.length - 1 === index ? 0 : "0 0 8px",
            }}
            label={<FCLabel>{item.label}</FCLabel>}
            value={item.value}
            control={
              <Radio style={{ padding: 0, marginRight: 4 }} color="primary" />
            }
          />
        ))}
      </RadioGroup>
    );
  };

  const [expanded, setExpanded] = useState({
    age_range: false,
    debt: false,
    gender: false,
    marital_status: false,
    risk_list: false,
    services: false,
    staffs: false,
    working_status: false,
  });
  const FilterTab = (props) => {
    return (
      <FTabComponent>
        <div
          className="header"
          onClick={async () => {
            if (!expanded[props.typeKey] && props.onOpen) await props.onOpen();
            if (expanded[props.typeKey] && props.onClose) await props.onClose();

            // Switch'in değişmesi durumunda da onClose/onOpen fonksiyonlarının çalışması için
            if (expanded[props.typeKey]) {
              if (props.onClose) await props.onClose();
            } else {
              if (props.onOpen) await props.onOpen();
            }
          }}
        >
          <span className="title">{props.title ?? ""}</span>
          <Switch
            checked={expanded[props.typeKey]}
            onChange={(e) => {
              setExpanded((prev) => ({
                ...prev,
                [props.typeKey]: e.target.checked,
              }));
            }}
            size="small"
            color="primary"
          />
        </div>
        {expanded[props.typeKey] && (
          <div className="inner">{props.children}</div>
        )}
      </FTabComponent>
    );
  };

  useEffect(() => {
    async function fetchData() {
      loadData();
      getTemplates();
      getMessageSettings();
      getShortcode();
      await getAllCategories();
      await checkWp();
    }
    fetchData();
  }, []);

  const FilterArea = FilterAreaBuilder;
  const Customers = CustomersBuilder;
  const ExceptedCustomers = ExceptedCustomersBuilder;
  const SmsSettings = SmsSettingsBuilder;

  return (
    <>
      <AuthContainer authorities={[1]} limited_permission="sms">
        <Grid className="mb-2">
          {process.env.APP_NAME !== "management" && (
            <Column className="xs-12 sm-12 md-auto">
              <StatsCardContainer>
                <Grid
                  style={{ height: "100%", minHeight: 73 }}
                  className="no-gutters-all"
                >
                  <Column className="xs-12">
                    <IconAlignContainer className="fc-orange bg-orange-opacity">
                      <FeaturedPlayList
                        style={{
                          fontSize: "2em",
                        }}
                      />
                    </IconAlignContainer>
                  </Column>
                  <Column className="xs-12 col-auto">
                    <TextAlignContainer>
                      <StatsHeader>{context.t(`["sms/filter"].smsMessageTitle`)}</StatsHeader>
                      <StatsValue>{sender}</StatsValue>
                    </TextAlignContainer>
                  </Column>
                </Grid>
              </StatsCardContainer>
            </Column>
          )}
          <Column className="xs-12 sm-12 md-auto">
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{context.t(`["sms/filter"].wpMessageTitle`)}</StatsHeader>
                    <StatsValue>{wp_phone}</StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
          {process.env.APP_NAME !== "management" && (
            <Column className="xs-12 sm-12 md-auto">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sender === "" ? false : checkedSMS}
                      onChange={(e) => {
                        if (sender !== "") {
                          setCheckedSMS(e.target.checked);
                        }
                      }}
                      color="primary"
                    />
                  }
                  label={context.t(`sms.sendType.sendSMS`)}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedWP}
                      onChange={(e) => {
                        if (!wp_state) {
                          history.push("/settings/wp");
                        }
                        setCheckedWP(e.target.checked);
                      }}
                      color="primary"
                    />
                  }
                  label={context.t(`sms.sendType.sendWP`)}
                />
              </FormGroup>
            </Column>
          )}
          {process.env.APP_NAME !== "management" && (
            <Column className="xs-12 sm-12 md-2">
              <StatsCardContainer>
                <Grid
                  style={{ height: "100%", minHeight: 73 }}
                  className="no-gutters-all"
                >
                  <Column className="xs-12">
                    <IconAlignContainer className="fc-orange bg-orange-opacity">
                      <FeaturedPlayList
                        style={{
                          fontSize: "2em",
                        }}
                      />
                    </IconAlignContainer>
                  </Column>
                  <Column className="xs-12 col-auto">
                    <TextAlignContainer>
                      <StatsHeader>{context.t(`sms.credit.sms`)}</StatsHeader>
                      <StatsValue>
                        {" "}
                        {context.state.balance?.data?.credit}
                      </StatsValue>
                    </TextAlignContainer>
                  </Column>
                </Grid>
              </StatsCardContainer>
            </Column>
          )}
          <Column
            className={
              process.env.APP_NAME !== "management"
                ? "xs-12 sm-12 md-2"
                : "xs-12 sm-12 md-6"
            }
          >
            <StatsCardContainer>
              <Grid
                style={{ height: "100%", minHeight: 73 }}
                className="no-gutters-all"
              >
                <Column className="xs-12">
                  <IconAlignContainer className="fc-green bg-green-opacity">
                    <WhatsApp
                      style={{
                        fontSize: "3em",
                      }}
                    />
                  </IconAlignContainer>
                </Column>
                <Column className="xs-12 col-auto">
                  <TextAlignContainer>
                    <StatsHeader>{context.t(`sms.credit.wp`)}</StatsHeader>
                    <StatsValue>
                      {context.state.balance?.data?.wp_credit}
                    </StatsValue>
                  </TextAlignContainer>
                </Column>
              </Grid>
            </StatsCardContainer>
          </Column>
        </Grid>
        <Grid>
          {(sender === "" || sender === null) && !wp_state ? (
            <>
              {process.env.APP_NAME !== "management" && (
                <UndefinedSenderAlert />
              )}
              <WpUndefinedAlert />
            </>
          ) : (
            <></>
          )}
          <SenderFetchAlert />

          <FilterArea />
          <Customers />
          <SmsSettings />
          <ExceptedCustomers />
        </Grid>
        <SMSReview
          open={open_sms_review_dialog}
          closeHandler={() => {
            setOpenSmsReviewDialog(false);
          }}
          sendSMS={sendFilteredSms}
          sms_content={message}
          total_number={total_number}
          total_sms_credit={context.state.balance?.data?.credit}
          in_use_sms_credit={total_sms_credit}
          total_wp_credit={context.state.balance?.data?.wp_credit}
          in_use_wp_credit={total_number}
        />
      </AuthContainer>
    </>
  );
};

export default SendFilter;

const FTabComponent = styled.div`
  width: 100%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.04);
  margin-bottom: 16px;

  .header{
    height: 54px;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding 0 16px;

    span.title {
      font-size: 15.6px;
      line-height: 1;
      font-weight: 500;
      margin: 0;
      padding: 0;
    }
  }

  .inner{
    padding: 0 16px 16px;
  }

`;

const ChipContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;
/*
const MessagePreview = styled.div`
  h5{ font-size: 14px; padding: 0; margin: 0; font-weight: 500; color: #555 }
  p{font-size: 15px; white-space: pre-wrap; line-height: 1.4}
`;*/

const StatsCardContainer = styled.div`
  position: relative;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  min-height: 73px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04);
`;

const IconAlignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 5px;
`;

const TextAlignContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
    padding: 16px;
  }
`;

const StatsHeader = styled.h1`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #aaa;
  padding-bottom: 10px;
`;

const StatsTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 0;
  color: #a0a0a0;
`;

const StatsValue = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0;
  padding: 0;
`;
