import React, { useContext, useEffect, useState, useCallback } from "react";
import AppContext from "../../context/store";
import DatePickerInput from "../../theme/CustomMUI/DatePickerInput";
import FullScreenDialog from "../../theme/CustomMUI/FullScreenDialog";
import Input from "../../theme/CustomMUI/Input";
import { Column, Grid } from "../../theme/Grid";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import moment from "moment";
import Select from "../../theme/CustomMUI/Select";
import Table from "../../theme/Table";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ExcelButton from "../Buttons/ExcelButton";
import styled from "styled-components";
import { DateRange, Search } from "@material-ui/icons";
import AlertDialog from "../../theme/CustomMUI/AlertDialog";
import { debounce } from 'lodash';
import AppointmentCardDetail from "./AppointmentCardDetail";
import useHandleParams from "../../functions/HandleParams";
import youtube_image from "../../assets/images/youtube_image.jpg";
import { IoMdClose } from "react-icons/io";
import { Dialog } from "@material-ui/core";
import YouTube from "react-youtube";

const ShortButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const ShortButton = styled.div`
  border-radius: 8px;
  width: 12vw;
  padding: 1vh 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const FilterButton = styled.div`
  border-radius: 8px;
  padding: 1vh 2.5vw;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: rgb(219, 146, 92);
  border: 1px solid rgba(249, 186, 132, 0.51);
  background-color: rgba(249, 186, 132, 0.21);
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 10px;
`;

const FilterAppointment = () => {
  const context = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const HandleParams = useHandleParams();
  const app_name = process.env.APP_NAME;

  const [loaded, setLoaded] = useState(false);
  const [isCreatedAtASCDESC, setIsCreatedAtASCDESC] = useState(false); // true -> asc | false -> desc
  const [isNameASCDESC, setIsNameASCDESC] = useState(false); // true -> asc | false -> desc
  const [isAppointmentAtASCDESC, setIsAppointmentAtASCDESC] = useState(false); // true -> asc | false -> desc
  const [isListed, setIsListed] = useState(null)
  const [fieldId, setFieldId] = useState(null)
  const urlPage = parseInt(
    new URLSearchParams(window.location.search).get("page")
  );
  const [youtubePopUp, setYoutubePopUp] = useState(false);
  const [pagination, setPagination] = useState({
    total_page: null,
    page: urlPage ? urlPage : 1,
    onChange: (page) => setPagination((prev) => ({ ...prev, page: page })),
  });
  const [appointmentData, setAppointmentData] = useState([]);
  const [staffs, setStaffs] = useState([]);

  const [staff_id, setStaffID] = useState(null);
  const [service_key, setServiceKey] = useState("");
  const [customer_key, setCustomerKey] = useState("");
  const [appointment_state, setAppointmentState] = useState(null);

  const [filter, setFilter] = useState({
    between: {
      start: moment().local().subtract(30, "days").toISOString(),
      end: moment().local().add(1, "days").toISOString(),
    },
  });
  const searchParams = new URLSearchParams(location.search);

  const [tempFilter, setTempFilter] = useState({
    between: {
      start: moment().local().subtract(30, "days").toISOString(),
      end: moment().local().add(1, "days").toISOString(),
    },
  });
  const [_appointment, set_Appointment] = useState(null);
  const [appointmentDetailModal, setAppointmentDetailModal] = useState(false);
  const [createAppointmentModal, setCreateAppointmentModal] = useState(false);
  const [hourDetail, setHourDetail] = useState({
    start_hour: context.state.company_shift.start_hour,
    end_hour: context.state.company_shift.end_hour,
  });
  const [timeUpgrade, setTimeUpgrade] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [shortCode] = useState(context.state.company?.short_code || null);
  const [smsTemplates] = useState([]);

  const [filterPicker] = useState([
    {
      id: 1,
      label: context.t(`accounting.today`),
      offset: 1,
    },
    {
      id: 2,
      label: context.t(`accounting.thisWeek`),
      offset: 3,
    },
    {
      id: 3,
      label: context.t(`accounting.thisMonth`),
      offset: 4,
    },
  ]);
  const [selected_filter_picker, setSelected_filter_picker] = useState(null);

  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [readyToUseFilter, setReadyToUseFilter] = useState(null);
  const [reportsDate, setReportsDate] = useState({
    between: {
      start: moment().local().format("DD/MM/YYYY"),
      end: moment().local().format("DD/MM/YYYY"),
    },
  });

  const getAppointmentsByFilter = () => {
    setLoaded(false);
    const { between } = filter;
    Axios.get(
      `${context.api_endpoint}/company/appointment/list`, selected_filter_picker === null ? {
        params: {
          page: pagination.page ? pagination.page : urlPage ? urlPage : 1,
          start: between.start.split("T")[0],
          end: between.end.split("T")[0],
          isbetween: selected_filter_picker ? null : true,
          staffid: staff_id,
          servicekey: service_key ? service_key : null,
          customerkey: customer_key ? customer_key : null,
          appointmentstate: appointment_state,
          field: fieldId,
          order: isListed ? 0 : 1,
        }
      } : {
      params: {
        page: pagination.page ? pagination.page : urlPage ? urlPage : 1,
        offset: selected_filter_picker.offset,
        isbetween: selected_filter_picker ? null : true,
        staffid: staff_id,
        servicekey: service_key ? service_key : null,
        customerkey: customer_key ? customer_key : null,
        appointmentstate: appointment_state,
        field: fieldId,
        order: isListed ? 0 : 1,
      },
    }
    )
      .then(({ data }) => {
        // console.log("data");
        setLoaded(true);
        setAppointmentData(
          [
            ...data.data.records.map((item) => ({
              ...item,
              customer: {
                ...item.customer,
                phone: `0${item.customer.phone.substring(
                  0,
                  3
                )} ${item.customer.phone.substring(
                  3,
                  6
                )} ${item.customer.phone.substring(6, 10)}`,
              },
              service_or_packet: item.service?.name || item.packet?.name || "-",
              created_at: moment(item.created_at).local().format("LLL"),
              appointment_start_date: moment(item.appointment_start_date)
                .local()
                .format("LLL"),
              note: item.note
                ? item.note.length > 40
                  ? `${item.note.substring(0, 40)}...`
                  : item.note
                : "-",
              app_request_id: item.app_request_id !== null ? (<span style={{ color: "#4595ee" }}>{context.t(`appointments.detail.appointmentStatusOnlineList`)}</span>) : <span style={{ color: "#ff9016" }}>{context.t(`appointments.detail.appointmentStatusPlatformList`)}</span>
            })),
          ].filter((item) => item !== undefined)
        );
        setPagination({
          ...pagination,
          page: data.data.page,
          total_page: data.data.records.length,
        });
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          toast.error(e.response?.data?.message);
        } else {
          toast.warning(e?.response?.data?.message);
        }
      });
  };

  const getAppointmentIndex = () => {
    Axios.get(`${context.api_endpoint}/company/staffs/unsafe`).then(
      (response) => {
        if (response.status === 200) {
          setStaffs([...response.data.data]);
        }
      }
    );
  };

  useEffect(() => {
    getAppointmentIndex();
  }, [])

  const handleFilterPickerClick = (selectedFilter) => {
    setPagination((prev) => ({ ...prev, page: 1 }));
    HandleParams('page', 1);
    setSelected_filter_picker(selectedFilter);

    let newStart, newEnd;

    switch (selectedFilter.offset) {
      case 1: // Bugün
        newStart = moment().startOf('day');
        newEnd = moment().endOf('day');
        break;
      case 3: // Bu Hafta
        newStart = moment().startOf('week');
        newEnd = moment().endOf('week');
        break;
      case 4: // Bu Ay
        newStart = moment().startOf('month');
        newEnd = moment().endOf('month');
        break;
      default:
        return;
    }

    setFilter({
      between: {
        start: newStart.toISOString(),
        end: newEnd.toISOString(),
      }
    });

    setReportsDate({
      between: {
        start: newStart.format("DD/MM/YYYY"),
        end: newEnd.format("DD/MM/YYYY"),
      }
    });


    setTempFilter({
      between: {
        start: newStart.toISOString(),
        end: newEnd.toISOString(),
      }
    });
  };

  useEffect(() => {
    getAppointmentsByFilter();
  }, [
    selected_filter_picker,
    filter.between.start,
    filter.between.end,
    customer_key,
    pagination.page,
    service_key,
    staff_id,
    appointment_state,
    isCreatedAtASCDESC,
    isNameASCDESC,
    isAppointmentAtASCDESC
  ]);

  const debouncedSetServiceKey = useCallback(
    debounce((value) => {
      setServiceKey(value);
    }, 800),
    []
  );

  const debouncedSetCustomerKey = useCallback(
    debounce((value) => {
      setCustomerKey(value);
    }, 800),
    []
  );


  useEffect(() => {
    return () => {
      debouncedSetServiceKey.cancel();
      debouncedSetCustomerKey.cancel();
    };
  }, [debouncedSetServiceKey, debouncedSetCustomerKey]);

  const getAppointmentsForSelectBox = async (staff_id, date) => {
    try {
      const response = await Axios.get(
        `${context.api_endpoint}/company/appointment/staff/${staff_id}/date/${moment(date).format("YYYY-MM-DD")}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching appointments:", error);
      return [];
    }
  };

  useEffect(() => {
    setLoaded(true);
    if (location.state?.selectedFilterPicker) {
      setSelected_filter_picker(location.state.selectedFilterPicker);
      handleFilterPickerClick(location.state.selectedFilterPicker);
    }
    setLoaded(false);
  }, [location.state]);

  const opts = {
    height: "330",
    width: "420",
  };
  return (
    <Grid>
      <div style={{
        position: "absolute",
        zIndex: "11",
        top: "91px",
        right: "25px",
        display: "flex",
        alignItems: "center"
      }}>
        {app_name === "salon" && (
          <img
            onClick={() => {
              setYoutubePopUp(true);
            }}
            src={youtube_image}
            style={{
              width: "70px",
              height: "37px",
              objectFit: "contain",
              cursor: "pointer",
            }}
          />
        )}
      </div>
      {app_name === "salon" && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={youtubePopUp}
          onClose={() => setYoutubePopUp(false)}
        >
          <div style={{ backgroundColor: "black" }}>
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <IoMdClose
                style={{
                  cursor: "pointer",
                  marginRight: "2px",
                  marginTop: "2px",
                }}
                onClick={() => setYoutubePopUp(false)}
                size={"24px"}
                color="white"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "3px",
              }}
            >
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"kuEh4uQQMlI"} opts={opts} />
                </div>
              </div>
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle2`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"afBhNMTomfc"} opts={opts} />
                </div>
              </div>
              <div>
                <h3
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {context.t(`appointments.youtubeTitle3`)}
                </h3>
                <div style={{ border: "1px solid gray" }}>
                  <YouTube videoId={"vngBLPm-q0E"} opts={opts} />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      <Column
        className="xs-12 sm-12 md-12 lg-12 mb-1 pb-2"
        style={{ borderBottom: "1px solid #b2b2b2" }}
      >
        <Column
          className="xs-12 sm-12 md-12 lg-6"
          style={{ height: "63px", display: "flex", alignItems: "center" }}
        >
          <ShortButtonContainer>
            {filterPicker.map(filter => (
              <ShortButton
                key={filter.id}
                onClick={() => handleFilterPickerClick(filter)}
                style={{
                  backgroundColor: selected_filter_picker?.id === filter.id
                    ? filter.id === 1 ? "rgb(49, 169, 243, 0.14)"
                      : filter.id === 2 ? "rgb(29, 139, 62, 0.21)"
                        : "rgb(254, 151, 151, 0.19)"
                    : "",
                  border: filter.id === 1 ? "1px solid rgb(91, 187, 245, 0.41)"
                    : filter.id === 2 ? "1px solid rgb(58, 154, 87, 0.35)"
                      : "1px solid rgb(185, 178, 178, 0.65)",
                  color: filter.id === 1 ? "rgb(49, 169, 243)"
                    : filter.id === 2 ? "rgb(29, 139, 62)"
                      : "rgb(254, 111, 111)"
                }}
              >
                {filter.label}
              </ShortButton>
            ))}
          </ShortButtonContainer>
        </Column>

        <Column
          className="xs-12 sm-12 md-12 lg-6"
          style={{ borderLeft: "1px solid #b2b2b2", height: "63px", display: "flex", alignItems: "center" }}
        >
          <FilterContainer>
            <FilterButton
              onClick={() => {
                setFilterModalOpen(true);
                setSelected_filter_picker(null);
                setPagination((prev) => ({ ...prev, page: 1 }));
                HandleParams('page', 1);
                setReadyToUseFilter(1);
              }}
            >
              <DateRange />
              <span style={{ fontWeight: "500", fontSize: "1.2em", paddingLeft: "1vw" }}>
                {reportsDate.between.start} - {reportsDate.between.end}
              </span>
            </FilterButton>
            <Column className="xs-12">
              <ExcelButton
                isPost={false}
                requestData={{
                  isExcel: true,
                  ...(selected_filter_picker === null ? {
                    start: filter.between.start.split("T")[0],
                    end: filter.between.end.split("T")[0],
                  } : {
                    offset: selected_filter_picker.offset,
                  }),
                  isbetween: selected_filter_picker ? null : true,
                  staffid: staff_id,
                  servicekey: service_key ? service_key : null,
                  customerkey: customer_key ? customer_key : null,
                  appointmentstate: appointment_state,
                  field: fieldId,
                  order: isListed ? 0 : 1,
                }}
                route="appointment/list"
              />
            </Column>
          </FilterContainer>
        </Column>
      </Column>

      {/* Filtre seçenekleri */}
      <Column className="xs-12 sm-12 md-12 lg-12 mb-2">
        <Grid>
          <Column className="xs-12 sm-6 md-3">
            <Select
              label={context.t(`['appointments/filter'].statusInputLabel`)}
              items={[
                {
                  label: context.t(`['appointments/filter'].statusInputItems[0]`),
                  value: "null",
                },
                {
                  label: context.t(`['appointments/filter'].statusInputItems[1]`),
                  value: 0,
                },
                {
                  label: context.t(`['appointments/filter'].statusInputItems[2]`),
                  value: 1,
                },
                {
                  label: context.t(`['appointments/filter'].statusInputItems[3]`),
                  value: 2,
                },
                {
                  label: context.t(`['appointments/filter'].statusInputItems[4]`),
                  value: 3,
                },
              ]}
              labelKey="label"
              valueKey="value"
              selected={`${appointment_state}`}
              handler={async (value) => setAppointmentState(value === "null" ? null : parseInt(value))}
            />
          </Column>
          <Column className="xs-12 sm-6 md-3">
            <Select
              label={context.t(`['appointments/filter'].staffInputLabel`)}
              items={[
                {
                  id: "null",
                  full_name: context.t(`['appointments/filter'].allStaffsText`),
                },
                ...staffs,
              ]}
              labelKey="full_name"
              valueKey="id"
              selected={`${staff_id}`}
              handler={async (staff_id) => setStaffID(staff_id !== "null" ? parseInt(staff_id) : null)}
            />
          </Column>
          <Column className="xs-12 sm-6 md-3">
            <Input
              label={context.t(`['appointments/filter'].serviceInputLabel`)}
              onChange={({ target: { value } }) => debouncedSetServiceKey(value)}
            />
          </Column>
          <Column className="xs-12 sm-6 md-3">
            <Input
              label={context.t(`['appointments/filter'].customerInputLabel`)}
              onChange={({ target: { value } }) => debouncedSetCustomerKey(value)}
            />
          </Column>
        </Grid>
      </Column>


      <Grid>
        <Column className="xs-12 sm-12">
          <Table
            refreshAction={() => {
              setIsAppointmentAtASCDESC(false);
              setIsCreatedAtASCDESC(false);
              setIsNameASCDESC(false);
            }}
            loaded={loaded}
            headings={{
              appointment_start_date: {
                label: context.t(
                  `['appointments/filter'].headings.appointmentStartDate`
                ),
                style: { width: 200 },
                sortable: {
                  0: [{ field: "`appointment`.`start_at`", order: "ASC" }],
                  1: [{ field: "`appointment`.`start_at`", order: "DESC" }],
                },
                isClicked: isAppointmentAtASCDESC,
                onClick: () => {
                  setIsListed(isListed ? !isListed : true)
                  setIsAppointmentAtASCDESC(!isAppointmentAtASCDESC)
                  setIsNameASCDESC(false)
                  setIsCreatedAtASCDESC(false)
                  setFieldId(2)
                },
              },
              "customer.full_name": {
                label: context.t(
                  `['appointments/filter'].headings.customerFullName`
                ),
                limited_line: 1,
                sortable: {
                  0: [
                    { field: "`customers`.`name`", order: "ASC" },
                    { field: "`customers`.`surname`", order: "ASC" },
                  ],
                  1: [
                    { field: "`customers`.`name`", order: "DESC" },
                    { field: "`customers`.`surname`", order: "DESC" },
                  ],
                },
                isClicked: isNameASCDESC,
                onClick: () => {
                  setIsListed(isListed ? !isListed : true)
                  setIsNameASCDESC(!isNameASCDESC)
                  setIsCreatedAtASCDESC(false)
                  setIsAppointmentAtASCDESC(false)
                  setFieldId(1)
                },
              },
              "service_or_packet": {
                label: context.t(
                  `['appointments/filter'].headings.serviceName`
                ),
                limited_line: 1,
                getValue: (row) => {
                  return row.service?.name || row.packet?.name || "-";
                }
              },

              "staff.full_name": {
                label: context.t(
                  `['appointments/filter'].headings.staffFullName`
                ),
                with_photo: "staff.detail.profile_photo",
                limited_line: 1,
              },
              "customer.phone": {
                label: context.t(
                  `['appointments/filter'].headings.customerPhone`
                ),
              },
              "app_request_id": {
                style: { width: 150 },
                label: (<div style={{ position: 'relative', display: 'inline-block' }}>
                  {context.t(`appointments.detail.appointmentStatusTable`)}
                  <span

                    className="bg-orange"
                    style={{
                      position: 'absolute',
                      right: -43,
                      top: -1,
                      color: 'white',
                      padding: '2px 7px',
                      borderRadius: '12px',
                      fontSize: '11px',
                      fontWeight: 'bold'
                    }}
                  >
                    {context.t(`appointments.detail.statusNew`)}
                  </span>
                </div>),
              },

              created_at: {
                label: context.t(`['appointments/filter'].headings.createdAt`),
                sortable: {
                  0: [{ field: "`appointment`.`created_at`", order: "ASC" }],
                  1: [{ field: "`appointment`.`created_at`", order: "DESC" }],
                },
                isClicked: isCreatedAtASCDESC,
                onClick: () => {
                  setIsListed(isListed ? !isListed : true)
                  setIsCreatedAtASCDESC(!isCreatedAtASCDESC)
                  setIsNameASCDESC(false)
                  setIsAppointmentAtASCDESC(false)
                  setFieldId(1)
                },
              },
              note: {
                label: context.t(`['appointments/filter'].headings.appointmentNote`),
                limited_line: 2,
              },
            }}
            rows={appointmentData}
            pagination={pagination}
            onRowClick={(row) => {
              try {
                const startDate = moment(row.appointment_start_date, "DD MMMM YYYY HH:mm");
                const endDate = moment(row.appointment_end_date);

                setHourDetail({
                  start_hour: startDate.format("HH:mm"),
                  end_hour: endDate.format("HH:mm")
                });

                const isPlatformAppointment = row.app_request_id.props.children === context.t(`appointments.detail.appointmentStatusPlatformList`);

                const appointmentData = {
                  ...row,
                  start_at: startDate.format("YYYY-MM-DD HH:mm:ss"),
                  end_at: endDate.format("YYYY-MM-DD HH:mm:ss"),
                  packet: row.packet || null,
                  service: row.service || null,
                  app_request_id: isPlatformAppointment ? null : row.app_request_id
                };

                setAppointmentDate(startDate.toDate());
                setSelectedStaff(row.staff);
                set_Appointment(appointmentData);
                setAppointmentDetailModal(true);

              } catch (error) {
                console.error("Error in onRowClick:", error);
                toast.error("Randevu detayı açılırken bir hata oluştu");
              }
            }}
          />

        </Column>
      </Grid>
      <AppointmentCardDetail
        _appointment={_appointment}
        set_Appointment={set_Appointment}
        updateAppointmentState={async (appointmentId, customerState) => {
          await Axios.put(
            `${context.api_endpoint}/company/appointment/customer/state`,
            {
              appointment_id: appointmentId,
              customer_state: customerState,
            }
          )
            .then((response) => {
              if (response.status === 200) {
                getAppointmentsByFilter();
                toast.success(
                  context.t(
                    `appointments.detail.appointmentStatus.changeStatusSuccessToast`
                  )
                );
              }
            })
            .catch((e) => {
              if (e.response.status === 401) {
                toast.error(e.response.data.message);
              } else {
                toast.warning(e.response.data.message);
              }
            });
        }}
        setAppointmentDetailModal={(isOpen) => {
          setAppointmentDetailModal(isOpen);
          if (!isOpen) {
            getAppointmentsByFilter();
          }
        }}
        setCreateAppointmentModal={setCreateAppointmentModal}
        appointmentDetailModal={appointmentDetailModal}
        hourDetail={hourDetail}
        setHourDetail={setHourDetail}
        weeklyAppointments={false}
        timeUpgrade={timeUpgrade}
        setTimeUpgrade={setTimeUpgrade}
        selectedStaff={selectedStaff}
        getAppointmentsForSelectBox={getAppointmentsForSelectBox}
        setAppointmentDate={setAppointmentDate}
        appointmentDate={appointmentDate}
        shortCode={shortCode}
        smsTemplates={smsTemplates}
      />
      <AlertDialog
        title={<h3 style={{ textAlign: "center" }}>{context.t(`accounting.filterArea.header`)}</h3>}
        open={filterModalOpen}
        maxWidth="sm"
        closeHandler={() => setFilterModalOpen(false)}
        buttons={[
          {
            title: context.t(`accounting.filterArea.approve`),
            icon: "check",
            backgroundColor: "primary-opacity",
            textColor: "primary",
            onClick: () => {
              setFilterModalOpen(false);
              setFilter(tempFilter);
              setSelected_filter_picker(null);
            },
          },
          {
            title: context.t(`accounting.filterArea.cancel`),
            icon: "close",
            textColor: "grey",
            onClick: () => {
              setTempFilter(filter);
              setFilterModalOpen(false);
            },
          },
        ]}
      >
        <Grid>
          <Column className="xs-12 sm-12 md-12 lg-12 mt-3 mb-5">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                width: "100%"
              }}
            >
              <DatePickerInput
                label={context.t(`reports.startInputLabel`)}
                value={moment(tempFilter.between.start).toDate()}
                maxDate={moment(tempFilter.between.end).toDate()}
                onChange={(date) => {
                  setTempFilter(prev => ({
                    between: {
                      ...prev.between,
                      start: moment(date).toISOString(),
                    }
                  }));
                  setReportsDate(prev => ({
                    between: {
                      ...prev.between,
                      start: moment(date).format("DD/MM/YYYY"),
                    }
                  }));
                }}
                fullWidth
                minDate={new Date('2019-01-01')}
              />
              <DatePickerInput
                label={context.t(`reports.endInputLabel`)}
                value={moment(tempFilter.between.end).toDate()}
                minDate={moment(tempFilter.between.start).toDate()}
                onChange={(date) => {
                  setTempFilter(prev => ({
                    between: {
                      ...prev.between,
                      end: moment(date).toISOString(),
                    }
                  }));
                  setReportsDate(prev => ({
                    between: {
                      ...prev.between,
                      end: moment(date).format("DD/MM/YYYY"),
                    }
                  }));
                }}
                fullWidth
              />
            </div>
          </Column>
        </Grid>
      </AlertDialog>
    </Grid>
  );
};

export default React.memo(FilterAppointment);
