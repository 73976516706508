import React, { useContext, useState } from "react";
import AppContext from "../../context/store";

import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/tr";
import "moment/locale/en-gb";

moment.locale("tr");

const DatePickerInput = (props) => {
  const context = useContext(AppContext);
  const [selectedDate, handleDateChange] = useState(props.value ?? new Date());

  return (
    <div
      className={props.className ? props.className : "mb-3"}
      style={props.styles}
    >
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale={context.state.default_locale}
      >
        {props.type === "time" ? (
          <DateTimePicker
            disablePast={props.disablePast}
            disableFuture={props.disableFuture}
            views={["year", "month", "date"]}
            minDateMessage=""
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={selectedDate}
            {...props}
            allowKeyboardControl={true}
            cancelLabel={context.t(`component.datePickerInput.cancelLabel`)}
            clearLabel={context.t(`component.datePickerInput.clearLabel`)}
            okLabel={context.t(`component.datePickerInput.okLabel`)}
            // inputStyle={{ textAlign: 'center' }}
            inputVariant="outlined"
            fullWidth
            clearables
            onChange={(date) => {
              handleDateChange(date);
              props.onChange
                ? props.onChange(date)
                : console.log("onChange is not defined!");
            }}
            format={props.format ?? "DD/MM/YYYY"}
          />
        ) : props.type === "keyboard" ? (
          <KeyboardDatePicker
            disablePast={props.disablePast}
            disableFuture={props.disableFuture}
            views={["year", "month", "date"]}
            minDateMessage=""
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={selectedDate}
            {...props}
            allowKeyboardControl={true}
            cancelLabel={context.t(`component.datePickerInput.cancelLabel`)}
            clearLabel={context.t(`component.datePickerInput.clearLabel`)}
            okLabel={context.t(`component.datePickerInput.okLabel`)}
            // inputStyle={{ textAlign: 'center' }}
            inputVariant="outlined"
            fullWidth
            clearables
            autoOk
            onChange={(date) => {
              handleDateChange(date);
              props.onChange
                ? props.onChange(date)
                : console.log("onChange is not defined!");
            }}
            format={props.format ?? "D MMMM dddd"}
          />
        ) : (
          <DatePicker
            disablePast={props.disablePast}
            disableFuture={props.disableFuture}
            views={["year", "month", "date"]}
            minDateMessage=""
            minDate={props.minDate}
            maxDate={props.maxDate}
            value={selectedDate}
            {...props}
            inputProps={{
              style: props.inputStyle
            }}
            allowKeyboardControl={true}
            cancelLabel={context.t(`component.datePickerInput.cancelLabel`)}
            clearLabel={context.t(`component.datePickerInput.clearLabel`)}
            okLabel={context.t(`component.datePickerInput.okLabel`)}
            // inputStyle={{ textAlign: 'center' }}
            inputVariant="outlined"
            fullWidth
            clearables
            autoOk
            onChange={(date) => {
              handleDateChange(date);
              props.onChange
                ? props.onChange(date)
                : console.log("onChange is not defined!");
            }}
            format={props.format ?? "DD/MM/YYYY"}
          />
        )}
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default DatePickerInput;
